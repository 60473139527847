import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import "./Login.css";
import { toast } from "react-toastify";
import axios from 'axios';
import Header from "../../components/Header";
import { decodeUrl } from "../../utils";
import Footer from "../../components/Footer";


function ActivateAccountScreen() {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const decodedToken = decodeUrl(token);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const activateAccountEndpoint = "/api/activateAccount";
    console.log("activateAccountEndpoint", activateAccountEndpoint);
    axios.post(activateAccountEndpoint ,{
        "email" : decodedToken,
    }, 
    {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then((response) => {
      console.log("response activateAccount", response.data);
      if (response.data.status) {
        setIsLoading(false);
        toast.success("Account Activate Successfully. Please Login!!", {
          position: "top-right",
          autoClose: 5000
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 5000)
      } else {
        setIsLoading(false);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 5000)
      }
    })
    .catch(function (error) {
      console.log("error", error);
      setIsLoading(false);
      toast.error("Something went wrong try again later!", {
        position: "top-right",
        autoClose: 5000
      });
      setTimeout(() => {
        window.location.href = "/login";
      }, 5000)
    });
  };



  return (
    <Header>
     {isLoading && <div class="whole-loading">Loading&#8230;</div>}
     <div className="app-center mt-5">
        <div className="container-fluid align-items-center justify-content-md-center mt-5">
          <div className="row align-items-center justify-content-md-center mt-5">
            <div className="col-1"></div>
            <div className="col-8 mx-auto mt-5 text-center">
              <div className="mt-5">
                <h1 className="mt-5">Activate Account</h1>
                <hr />
                <form onSubmit={handleSubmit} className="pt-5">
                  <h4>Dear User</h4>
                  <h3>Welcome on De QR Gids</h3>
                  <h3>Please click on the button below to activate your account.</h3>
                  <button type="submit" className={`mt-5 mb-5 btn green`}>Activate Account</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Header>
  );
}

export default ActivateAccountScreen;
