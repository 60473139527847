import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/create.css';
import { QRCodeSVG } from 'qrcode.react';
import { encodeUrl } from '../utils';
import Header from '../components/Header';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';
import Footer from '../components/Footer';

const QRCodeGenerator = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [qrCodeUrl, setQRCodeUrl] = useState('');
    const [qrCodeImage, setQRCodeImage] = useState('');
    const navigate = useNavigate();
    const svgRef = useRef(null);

    const [logo, setLogo] = useState(null);

    const [activeTab, setActiveTab] = useState("details");

    // Handlers
    // const handleLogoChange = (e) => setLogo(e.target.files[0]);
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    canvas.width = 75;
                    canvas.height = 75;

                    // Calculate the scaling and position
                    const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
                    const x = (canvas.width / 2) - (img.width / 2) * scale;
                    const y = (canvas.height / 2) - (img.height / 2) * scale;

                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

                    // Convert canvas to a Blob or data URL
                    canvas.toBlob((blob) => {
                        const resizedFile = new File([blob], file.name, { type: file.type });
                        setLogo(resizedFile);
                    });
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    };
    

    const tabs = [
        { name: "Details", id: "details", enabled: true },
        { name: "Logo", id: "logo", enabled: !isButtonDisabled },
        { name: "Color", id: "color", enabled: !isButtonDisabled },
    ];
    const [selectedColor, setSelectedColor] = useState("#000000");
    const colors = [
        "#FF5733", "#33FF57", "#3357FF", "#F1C40F", "#8E44AD",
        "#1ABC9C", "#E74C3C", "#3498DB", "#2ECC71", "#34495E",
        "#FFFFFF", "#000000"
    ];

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const handleCustomColorChange = (e) => {
        setSelectedColor(e.target.value);
    };

    useEffect(() => {
        getLocalStorageInfo();
    }, []);

    const getLocalStorageInfo = async () => {
        try {
            let localData = reactLocalStorage.getObject("user");
            if (Object.keys(localData).length != 0) {
                setUserInfo(localData)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (qrCodeUrl) {
            setIsLoading(true);
            setIsButtonDisabled(true);
            fetch('/api/verifyLinkTreeAvailableOrNot', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    logo_url: userInfo?.image,
                    unique_id: userInfo?.unique_id,
                    qrcode_type: qrCodeUrl?.includes("share-tree") ? "linktree" : "qrcode"
                }),
            }).then((response) => response.json())
                .then((response) => {
                    console.log('response', response)
                    if (response.status) {
                        toast.error("QRCode is already created for Link Tree, Please check Dashboard!!", {
                            position: "top-right",
                            autoClose: 6000
                        });
                        setIsLoading(false);
                        setIsButtonDisabled(false);
                    } else {
                        const svg = svgRef.current.querySelector('svg');
                        const svgData = new XMLSerializer().serializeToString(svg);
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        const img = new Image();

                        img.onload = async function () {
                            canvas.width = img.width;
                            canvas.height = img.height;
                            ctx.drawImage(img, 0, 0);
                            const pngFile = canvas.toDataURL('image/png');
                            setQRCodeImage(pngFile);

                        };

                        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
                    }
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                    setIsButtonDisabled(false);
                    console.log("error", error);
                    toast.error("Something went wrong try again later!", {
                        position: "top-right",
                        autoClose: 2000
                    });
                });
        }
    };


    useEffect(() => {
        if (qrCodeImage) {
            setIsLoading(true);
            const byteString = atob(qrCodeImage.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uint8Array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                uint8Array[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([uint8Array], { type: 'image/png' });
            const file = new File([blob], 'qrcode.png', { type: 'image/png' });

            const formData = new FormData();
            formData.append('user_id', userInfo?.id);
            formData.append('unique_id', userInfo?.unique_id);
            formData.append('qrcode_url', qrCodeUrl);
            formData.append('image', file);

            const qrcodeId = encodeUrl(qrCodeUrl);
            formData.append('qrcode_id', qrcodeId);

            formData.append('logo', logo);
            formData.append('qrcode_color', selectedColor);

            const qrcodeType = qrCodeUrl?.includes("share-tree") ? "linktree" : "qrcode"
            formData.append('qrcode_type', qrcodeType);

            fetch('/api/addQRCodes', {
                method: 'POST',
                body: formData,
            }).then(() => {
                setIsLoading(false);
                setIsButtonDisabled(false);
                toast.success("QRCode Created Successfully!!", {
                    position: "top-right",
                    autoClose: 2000
                });
                navigate(`/share/${qrcodeId}`);
                window.location.reload();
            }).catch((error) => {
                setIsLoading(false);
                setIsButtonDisabled(false);
                console.log("error", error);
                toast.error("Something went wrong try again later!", {
                    position: "top-right",
                    autoClose: 2000
                });
            });
        }
    }, [qrCodeImage])

    return (
        <Header>
            <div className="create-center">
                {isLoading && <div class="whole-loading">Loading&#8230;</div>}
                <div className="create-app-center pt-5">
                    <form onSubmit={handleSubmit} className="form-creation pt-5">
                        {/* Tabs Navigation */}
                        <h1>Create QR Code</h1>
                        <div style={{ display: "flex" }}>
                            {tabs.map((tab) => (
                                <button
                                    type='button'
                                    key={tab.id}
                                    disabled={!tab.enabled}
                                    onClick={() => setActiveTab(tab.id)}
                                    style={{
                                        padding: "10px 20px",
                                        cursor: tab.enabled ? "pointer" : "not-allowed",
                                        backgroundColor: activeTab === tab.id ? "#007bff" : "rgb(69 106 167 / 60%)",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "0px"
                                    }}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </div>

                        {/* Tab Content */}
                        <div style={{ padding: "20px", border: "1px solid #ccc", borderRadius: "5px" }}>
                            {activeTab === "details" && (
                                <div>
                                    <label>Add URL: </label>
                                    <input
                                        type="text"
                                        placeholder="Enter URL"
                                        value={qrCodeUrl}
                                        className="mb-5 inputBox"
                                        onChange={(e) => {
                                            setQRCodeUrl(e.target.value)
                                            setIsButtonDisabled(e.target.value ? false : true);

                                        }}
                                    />
                                </div>
                            )}

                            {activeTab === "logo" && (
                                <div>
                                    <label>Upload Logo: </label>
                                    <input className="logoInputBox" type="file" onChange={handleLogoChange} />
                                    {logo && <p>Selected File: {logo.name}</p>}
                                    <small>
                                        <b>Note: Please upload a logo  with 75px * 75px resolution.</b>
                                    </small>
                                </div>
                            )}

                            {activeTab === "color" && (
                                <div>
                                    <label>Set Color Hex Code: </label>
                                    {/* <input
                                    type="color"
                                    value={color}
                                    onChange={handleColorChange}
                                    placeholder="#000000"
                                    className="inputBox"
                                /> */}

                                    <div className="color-picker">
                                        <div className="color-picker-inline">
                                            {colors.map((color, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handleColorClick(color)}
                                                    className="color-circle"
                                                    style={{
                                                        backgroundColor: color,
                                                        border: selectedColor === color ? "3px solid black" : "2px solid #ccc",
                                                    }}
                                                />
                                            ))}
                                        </div>
                                        <div style={{ textAlign: "center", display: "flex", marginTop: "24px" }}>
                                            <input
                                                type="color"
                                                value={selectedColor}
                                                onChange={handleCustomColorChange}
                                                className="color-select"
                                            />
                                            <input
                                                type="text"
                                                value={selectedColor}
                                                onChange={handleCustomColorChange}
                                                className="color-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="mt-5 mb-5" ref={svgRef}>
                            {qrCodeUrl && <QRCodeSVG
                                value={qrCodeUrl}
                                size={256}
                                imageSettings={{
                                    src: logo ? URL.createObjectURL(logo) : null,
                                    excavate: logo ? true : false,
                                    height: logo ? 75 : 0,
                                    width: logo ? 75 : 0,
                                }}
                                fgColor={selectedColor ? selectedColor : "#000"}
                            />}
                        </div>

                        <button type="submit" disabled={isButtonDisabled} className={`${isButtonDisabled ? 'disabled' : ''} mt-5 mb-5 btn`}>Generate & Share</button>
                    </form>
                </div>
                <div class="demo-request col-md-12">
                    <span class="tabContent">
                        <p><span class="splunk2-eyebrow">PRICING PLANS</span></p>
                        <h2><span class="splunk2-h2">Choose a plan based on your business drivers</span></h2>
                        <p><span class="splunk-body">Work with your data, your way. With a variety of data models to choose from, you can find the approach that works best for you.</span></p>
                    </span>
                    <div class="splunk-button base-component parbase">
                        <a href="/pricing#estimate">
                            <button type="button" className={`mt-5 mb-5 btn estimate`}>Get Estimates & Demo</button>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </Header>
    );
};

export default QRCodeGenerator;
