import React, { useState, useEffect } from 'react';
import './css/style.css';
import DateFilter from './DateFilter';
import LeadOverviewChart from './chart/LeadOverviewChart';

const ReportWithLeadOverview = ({ reportWithLeadOverview }) => {
  const [filteredData, setFilteredData] = useState(reportWithLeadOverview);

  return (
    <>
      {reportWithLeadOverview && reportWithLeadOverview?.length > 0 && <div className="event-count-table card-container">
        <h6 className="text-left text-background" >*Below graphs show data that is atleast 24 hours old.</h6>
        <div className="filter-flex">
          <h4>Report With Lead Overview <small>(Display activity reports from the start date up to 24 hours prior.)</small></h4>
          <DateFilter data={reportWithLeadOverview} onFilter={setFilteredData} />
        </div>
        <LeadOverviewChart data={filteredData} />
      </div>}
    </>
  );
};

export default ReportWithLeadOverview;