import React from "react";
import "./style.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import termsConditions from '../../pdf/terms-and-conditions.pdf';


function Terms() {

  return (
    <Header>
      <div className="layout-center">
        <div className="container-fluid align-items-center justify-content-md-center mt-5">
          <div className="row align-items-center justify-content-md-center mt-5">
            <div className="col-1"></div>
            <div className="col-10 mx-auto mt-5">
              <div className="row mt-5">
                <div className="col-md-12 mt-5"></div>
                <div className="col-md-10 mt-5 fontStyle fontStyleTerms" translate="no">
                  <h1 translate="no">ALGEMENE VOORWAARDEN DEQRGIDS</h1>
                  <h6 translate="no">DATUM: 01-okt-2024</h6>

                  <button variant="contained" className="downloadBtn">
                    <a className="pdf_download" target="_blank" href={termsConditions}>
                      <span className="footer__headingsContents footer__headingsContents_download" translate="no">Download PDF</span>
                    </a>
                  </button><br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 1. DEFINITIES</h2>
                  <p translate="no">In deze algemene voorwaarden worden de volgende termen, steeds met hoofdletter beginnend, in de navolgende betekenis
                    gebruikt.</p>
                  <ol>
                    <li translate="no">DEQRGids: de gebruiker van deze algemene voorwaarden, onderdeel van European IT Solutions, gevestigd aan Tijnmuiden
                      59, 1046 AK te Amsterdam, ingeschreven in het Handelsregister onder KvK-nummer 63149117.</li>
                    <li translate="no">Klant: iedere rechtspersoon, dan wel natuurlijke persoon handelend in de uitoefening van een beroep of bedrijf, met wie
                      DEQRGids een Overeenkomst heeft gesloten of beoogt te sluiten.</li>
                    <li translate="no">Partijen: de Klant en DEQRGids gezamenlijk.</li>
                    <li translate="no">Overeenkomst: iedere tussen Partijen tot stand gekomen overeenkomst in het kader waarvan DEQRGids zich tegenover de
                      Klant heeft verbonden tot het voor onbepaalde tijd in gebruik geven van het Platform.</li>
                    <li translate="no">Platform: de door DEQRGids ontwikkelde en in het kader van de Overeenkomst middels www.deqrgids.nl aan de Klant in
                      gebruik te geven webruimte voor het aanmaken van zogenaamde social media link trees en individuele links waarmee
                      eenieder via een door het Platform gegeneerde QR-code toegang heeft tot de door de Klant geregistreerde social
                      mediakanalen of andere content.</li>
                    <li translate="no">Schriftelijk: zowel communicatie op schrift als communicatie per e-mail.</li>
                  </ol>
                  <br />


                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 2. | ALGEMENE BEPALINGEN</h2>
                  <ol>
                    <li translate="no">Deze algemene voorwaarden zijn van toepassing op elk aanbod van DEQRGids tot het aangaan van een Overeenkomst,
                      iedere Overeenkomst als zodanig en alle daaruit tussen Partijen voortvloeiende rechtsverhoudingen.</li>
                    <li translate="no">De toepasselijkheid van de eventuele algemene voorwaarden van de Klant, onder welke benaming dan ook aangeduid,
                      wordt uitdrukkelijk van de hand gewezen.</li>
                    <li translate="no">Van het bepaalde in deze algemene voorwaarden kan uitsluitend uitdrukkelijk en Schriftelijk worden afgeweken. Indien en
                      voor zover hetgeen Partijen uitdrukkelijk en Schriftelijk zijn overeengekomen, afwijkt van het bepaalde in deze algemene
                      voorwaarden, geldt hetgeen Partijen uitdrukkelijk en Schriftelijk zijn overeengekomen.</li>
                    <li translate="no">Vernietiging of nietigheid van een of meer van de bepalingen uit deze algemene voorwaarden of de Overeenkomst als
                      zodanig, laat de geldigheid van de overige bedingen onverlet. In een voorkomend geval zijn Partijen verplicht in onderling
                      overleg te treden teneinde een vervangende regeling te treffen ten aanzien van het aangetaste beding. Daarbij wordt zoveel
                      mogelijk het doel en de strekking van de oorspronkelijke bepaling in acht genomen.</li>
                  </ol>
                  <br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 3. | AANBOD EN TOTSTANDKOMING VAN OVEREENKOMSTEN</h2>
                  <ol>
                    <li translate="no">Voor totstandkoming van de Overeenkomst is vereist dat de Klant zich op het Platform heeft geregistreerd en DEQRGids de
                      registratie middels een bevestiging per e-mail heeft goedgekeurd. De Klant dient alle bij de registratie gevraagde verplichte
                      gegevens volledig en naar waarheid te verstrekken. De Klant staat in voor de juistheid en volledigheid van de door hem
                      verstrekte gegevens. DEQRGids is nimmer aansprakelijk voor schade ontstaan doordat hij is uitgegaan van door de Klant
                      verstrekte onjuiste of onvolledige gegevens.</li>
                    <li translate="no">Het aanbod van DEQRGids tot registratie op het Platform is vrijblijvend; DEQRGids is nimmer verplicht de registratie van de
                      Klant te accepteren en te bevestigen.</li>
                    <li translate="no">Aan een aanbod van DEQRGids dat een kennelijke fout of vergissing bevat, kan de Klant geen rechten ontlenen.</li>
                    <li translate="no">De Overeenkomst komt pas tot stand op het moment dat DEQRGids de registratie van de Klant per e-mail aan de Klant heeft
                      bevestigd.</li>
                  </ol>
                  <br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 4. | HET PLATFORM EN GEBRUIKSVOORWAARDEN</h2>
                  <ol>
                    <li translate="no">Het Platform biedt de Klant de mogelijkheid om zogenaamde social media link trees en individuele links aan te maken
                      waarmee eenieder via een door het Platform gegeneerde QR-code toegang heeft tot de door de Klant geregistreerde social
                      mediakanalen of andere content. Het Platform genereert voor de Klant inzichtelijke statistieken en rapporten in verband
                      met het gebruik van de QR-links van de Klant. Deze statistieken zijn anoniem en bevatten dan ook niet naar personen
                      herleidbare gegevens.</li>
                    <li translate="no">De Klant mag de gegeneerde QR-codes gebruiken op elke door hem gewenste wijze en de QR-codes van de Klant worden
                      ook op de website van DEQRGids geplaatst. Indien de Overeenkomst eindigt, verliezen de QR-codes evenwel hun werking
                      en worden deze van de website van DEQRGids verwijderd. Indien onder het account van de Klant wijzigingen worden
                      aangebracht in de links waarnaar de betreffende QR-code verwijst, beïnvloedt dit de bestemming van de QR-code op
                      overeenkomstige wijze en wordt de bestemming van de QR-code zoals geopenbaard op de website van DEQRGids, op
                      overeenkomstige wijze gewijzigd. De Klant wordt geacht daarmee bekend te zijn. Het is de eigen verantwoordelijkheid van de Klant om, indien gewenst, de bestemming van QR-codes en de Overeenkomst in stand te laten. DEQRGids aanvaardt
                      geen enkele aansprakelijkheid voor de gevolgen van de ondeugdelijke werking van QR-codes of een onbedoelde
                      bestemming daarvan, en behoudt zich altijd het recht voor om de Overeenkomst te beëindigen op de gronden zoals in deze
                      algemene voorwaarden beschreven.</li>
                    <li translate="no">Gedurende de looptijd van de Overeenkomst biedt DEQRGids de Klant een beperkt, niet-exclusief, niet-overdraagbaar en
                      niet-sublicenseerbaar gebruiksrecht ten aanzien van het Platform en onderdelen daarvan, doch uitsluitend indien en voor
                      zover dit gebruiksrecht conform het bepaalde in het onderhavige artikel van deze algemene voorwaarden aan de Klant
                      toekomt. Deze rechten zijn dan ook niet overdraagbaar aan derden, met dien verstande dat de Klant het Platform in gebruik
                      mag geven aan zijn eventuele personeel, mits gebruik van het Platform uitsluitend geschiedt voor doeleinden gerelateerd
                      aan het genereren van QR-codes voor toegang tot de social mediakanalen en/of andere content met betrekking tot het
                      bedrijf van de Klant. Alle (intellectuele) eigendomsrechten op het Platform en de daaraan verbonden software blijven bij
                      DEQRGids rusten.</li>
                    <li translate="no">Gebruik van het Platform is uitsluitend toegestaan overeenkomstig de doeleinden waarvoor het Platform is ontwikkeld en
                      bestemd.</li>
                    <li translate="no"> De Klant staat ervoor in dat hij zich onthoudt van ongeoorloofd gebruik van het Platform en zich zal opstellen en gedragen
                      conform hetgeen door DEQRGids mag worden verwacht van een behoorlijke gebruiker van het Platform. In het bijzonder is
                      het niet toegestaan inloggegevens voor toegang tot het Platform buiten het bedrijf van de Klant te delen met derden.  </li>
                    <li translate="no">Het is verboden pogingen te ondernemen om de in verband met het Platform gebruikte programmatuur te decompileren of
                      na te maken, deze op enige andere wijze te manipuleren of programmatuur te ontwikkelen die een inbreuk maakt op de
                      rechten van DEQRGids.</li>
                    <li translate="no">DEQRGids zal zich inspannen om gedurende de looptijd van de Overeenkomst een optimale werking van het Platform te
                      realiseren en zal - waar nodig - ondersteuning bieden op verzoek van de Klant, doch verbindt zich ter zake nimmer tot
                      bepaalde (reactie)termijnen.</li>
                    <li translate="no">DEQRGids kan het Platform mogelijk uitbreiden met nieuwe functionaliteiten bevatten. Nieuwe functionaliteiten die tegen
                      hogere prijzen aan nieuwe klanten van DEQRGids worden aangeboden, vallen niet automatisch onder de reikwijdte van de
                      Overeenkomst. Indien de Klant gebruik wenst te maken van dergelijke nieuwe functionaliteiten, dient hij hierover nadere
                      afspraken te maken met DEQRGids.. </li>
                  </ol>
                  <br />


                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 5. | LOOPTIJD EN OPZEGGING VAN OVEREENKOMSTEN</h2>
                  <ol>
                    <li translate="no">De Overeenkomst wordt aangegaan voor onbepaalde tijd. Partijen kunnen de Overeenkomst opzeggen met inachtneming
                      van een opzegtermijn van een maand. De Klant kan de Overeenkomst opzeggen op de onder zijn account op het Platform
                      aangegeven wijze.</li>
                    <li translate="no">Het recht van de Klant op het gebruik van het Platform eindigt van rechtswege door het eindigen van de Overeenkomst. Op
                      DEQRGids rust na beëindiging van de Overeenkomst geen enkele bewaarplicht ten aanzien van welke gegevens dan ook die
                      middels het Platform zijn opgeslagen. Deze gegevens worden binnen een maand na het eindigen van de Overeenkomst
                      verwijderd, met uitzondering van de gegevens van de Klant die bij de registratie door hem zijn opgegeven.</li>
                  </ol>
                  <br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 6. | OVERMACHT</h2>
                  <ol>
                    <li translate="no">DEQRGids is niet gehouden tot het nakomen van enige verplichting uit de Overeenkomst indien en voor zolang hij daartoe
                      gehinderd wordt door een omstandigheid die hem krachtens de wet, een rechtshandeling of de in het maatschappelijk
                      verkeer geldende opvattingen niet kan worden toegerekend (overmacht). Onder overmacht wordt, naast hetgeen
                      daaromtrent in de wetgeving en jurisprudentie wordt begrepen, verstaan alle van buiten komende oorzaken waarop
                      DEQRGids geen invloed heeft en die de (verdere) uitvoering van de Overeenkomst onmogelijk maken of ernstig bemoeilijken,
                      zoals technische storingen, brand, stroomstoringen en storingen in communicatieverbindingen, zoals wat betreft gebruik
                      van het Platform.</li>
                    <li translate="no">Schade als gevolg van overmacht komt niet voor vergoeding in aanmerking.</li>
                  </ol>
                  <br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 7. | OPSCHORTING EN ONTBINDING</h2>
                  <ol>
                    <li translate="no">DEQRGids is bevoegd de uitvoering van de Overeenkomst op te schorten en daarmee het account van de Klant tijdelijk te
                      blokkeren, indien en voor zolang de Klant zijn reeds opeisbare verplichtingen uit de Overeenkomst (waaronder het bepaalde
                      in deze algemene voorwaarden aldus mede begrepen) niet nakomt. </li>
                    <li translate="no">DEQRGids is bevoegd de Overeenkomst met directe ingang geheel of gedeeltelijk te ontbinden, indien de Klant zijn
                      verplichtingen uit de Overeenkomst niet, niet tijdig of niet volledig nakomt. Indien de nakoming van de verplichtingen van de
                      Klant ten aanzien waarvan hij tekortschiet, niet blijvend onmogelijk is, ontstaat de bevoegdheid tot ontbinding pas nadat de
                      Klant Schriftelijk door DEQRGids in gebreke is gesteld, in welke ingebrekestelling een redelijke termijn is vermeld
                      waarbinnen de Klant zijn verplichtingen (alsnog) kan nakomen en de nakoming na verstrijken van de laatstbedoelde termijn nog steeds is uitgebleven. Het bepaalde in de vorige zin geldt niet indien DEQRGids uit een mededeling van de Klant moet
                      afleiden dat de Klant blijvend in de nakoming zal tekortschieten, in welk geval een ingebrekestelling dan ook zinloos is.</li>
                    <li translate="no">Het bepaalde in de vorige twee leden geldt tenzij de tekortkoming van de Klant, gezien haar bijzondere aard of geringe
                      betekenis, de opschorting c.q. ontbinding met haar gevolgen redelijkerwijs niet rechtvaardigt.</li>
                    <li translate="no">DEQRGids is tevens gerechtigd om de Overeenkomst met onmiddellijke ingang geheel of gedeeltelijk te ontbinden indien
                      de Klant in staat van faillissement verkeert, enig beslag op zijn goederen is gelegd of anderszins niet vrijelijk over zijn
                      vermogen kan beschikken.</li>
                    <li translate="no">Voorts is DEQRGids gerechtigd de Overeenkomst geheel of gedeeltelijk te ontbinden indien zich omstandigheden voordoen
                      welke van dien aard zijn dat nakoming van de Overeenkomst onmogelijk is of ongewijzigde instandhouding daarvan in
                      redelijkheid niet van hem kan worden gevergd, bijvoorbeeld in geval van beëindigen van de exploitatie van het Platform.</li>
                    <li translate="no">De Klant maakt nimmer aanspraak op enige vorm van schadevergoeding in verband met het door DEQRGids op grond van
                      dit artikel uitgeoefende opschortings- en/of ontbindingsrecht.</li>
                    <li translate="no">Indien de grond die tot opschorting of ontbinding van de Overeenkomst heeft geleid, aan de Klant kan worden toegerekend
                      (wat slechts in geval van lid 5 niet altijd zo hoeft te zijn), maakt DEQRGids jegens de Klant aanspraak op vergoeding van de
                      schade die DEQRGids dientengevolge lijdt</li>
                    <li translate="no">Indien DEQRGids de Overeenkomst op grond van dit artikel ontbindt, zijn alle eventuele nog openstaande vorderingen op
                      de Klant onmiddellijk opeisbaar.</li>
                  </ol>
                  <br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 8. | PRIJZEN EN BETALINGEN</h2>
                  <ol>
                    <li translate="no">Het aanbod van DEQRGids vermeldt uitdrukkelijk de door de Klant verschuldigde maandprijs voor gebruik van het Platform,
                      tenzij uitdrukkelijk in het aanbod is vermeld dat uit de Overeenkomst geen betalingsverplichting voor de Klant voortvloeit.</li>
                    <li translate="no">De door DEQRGids vermelde maandprijs is exclusief btw, tenzij door DEQRGids uitdrukkelijk anders is aangegeven.</li>
                    <li translate="no">DEQRGids is gerechtigd om de overeengekomen prijs te wijzigen. In een voorkomend geval doet DEQRGids daarvan uiterlijk
                      twee maanden vóór inwerkingtreding van de prijswijziging Schriftelijk mededeling aan Klant.</li>
                    <li translate="no">Betaling geschiedt jaarlijks voor de aankomende 12 maanden, tenzij uitdrukkelijk anders is overeengekomen. Betalingen
                      dienen te geschieden op de daartoe door DEQRGids aangewezen wijze en binnen de door DEQRGids vermelde termijn.</li>
                    <li translate="no">In geval van betaling aan DEQRGids middels automatische incasso is overeengekomen, de machtiging tot automatische
                      incasso wordt ingetrokken of in geval een betaling wordt gestorneerd, dan dient de betaling alsnog middels overboeking te
                      geschieden, binnen de door DEQRGids op de betreffende factuur vermelde termijn. In dat geval is DEQRGids gerechtigd de
                      aan de stornering verbonden kosten aan de Klant door te berekenen.</li>
                    <li translate="no">DEQRGids is gerechtigd om de aan de Klant toekomende facturen, uitsluitend per e-mail aan hem beschikbaar te stellen.</li>
                    <li translate="no">De Klant is steeds gehouden te betalen zonder beroep op opschorting of verrekening.</li>
                    <li translate="no">Indien tijdige betaling achterwege blijft, treedt het verzuim van de Klant van rechtswege in. Vanaf de dag dat dit verzuim
                      intreedt, is de Klant, over het openstaande bedrag, een rente van 2% per maand verschuldigd, waarbij een gedeelte van een
                      maand als volledige maand wordt aangemerkt.</li>
                    <li translate="no">Alle redelijke kosten, zowel gerechtelijke, buitengerechtelijke als executiekosten, gemaakt ter verkrijging van door de Klant
                      verschuldigde bedragen, komen voor rekening van de Klant.</li>
                  </ol>
                  <br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 9. | AANSPRAKELIJKHEID EN VRIJWARING</h2>
                  <ol>
                    <li translate="no">DEQRGids is niet aansprakelijk voor schade als gevolg van onbevoegd gebruik van inloggegevens voor toegang tot het
                      Platform. </li>
                    <li translate="no">DEQRGids is niet aansprakelijk voor de gevolgen van wanneer DEQRGids niet langer statistieken in verband met het gebruik
                      van QR-codes voor de klant kan genereren omdat de verwerking van data door derden waarvan DEQRGids afhankelijk is,
                      niet langer wordt aangeboden.</li>
                    <li translate="no">DEQRGids spant zich in om de juiste werking en de toegankelijkheid van het Platform te optimaliseren. Echter kan
                      DEQRGids niet garanderen dat het Platform onbeperkt beschikbaar is en dat alle voorzieningen van het Platform steeds
                      probleemloos functioneren. Alle aansprakelijkheid van DEQRGids ter zake is uitgesloten. Voorts is DEQRGids niet
                      gehouden de Klant ter zake op enige andere wijze te compenseren.</li>
                    <li translate="no">Aansprakelijkheid van DEQRGids voor indirecte schade, gevolgschade, gederfde winst, gemiste besparingen, verminderde
                      goodwill, schade door bedrijfsstagnatie, schade als gevolg van aanspraken van relaties van de Klant, verminking of verlies
                      van data en alle andere vormen van schade dan genoemd in het volgende lid, uit welken hoofde dan ook, is uitgesloten.</li>
                    <li translate="no">De in deze algemene voorwaarden opgenomen beperkingen van de aansprakelijkheid van DEQRGids gelden niet indien de
                      schade te wijten is aan opzet of bewuste roekeloosheid van DEQRGids.</li>
                    <li translate="no">In geval niettegenstaande de in de vorige leden van dit artikel vervatte aansprakelijkheidsuitsluitingen enige
                      aansprakelijkheid op DEQRGids mocht rusten, zal de aansprakelijkheid te allen tijde beperkt zijn tot eenmaal de
                      factuurwaarde van de Overeenkomst, althans dat gedeelte van de Overeenkomst waarop de aansprakelijkheid betrekking heeft, of, indien de Overeenkomst reeds een langere looptijd heeft dan zes maanden, het totaal van de prijs over de laatste
                      zes maanden van de Overeenkomst.</li>
                    <li translate="no">In afwijking van de wettelijke verjaringstermijn, bedraagt de verjaringstermijn van alle vorderingen en verweren tegenover
                      DEQRGids een jaar.</li>
                    <li translate="no">De Klant vrijwaart DEQRGids van eventuele aanspraken van derden die in verband met de uitvoering van de Overeenkomst
                      schade lijden en waarvan de oorzaak aan anderen dan aan DEQRGids toerekenbaar is. Indien DEQRGids uit dien hoofde
                      door derden mocht worden aangesproken, dan is de Klant gehouden DEQRGids zowel buiten als in rechte bij te staan en
                      onverwijld al hetgeen te doen dat van hem in dat geval redelijkerwijs verwacht mag worden. Mocht de Klant in gebreke blijven
                      in het nemen van adequate maatregelen, dan is DEQRGids, zonder ingebrekestelling, gerechtigd zelf daartoe over te gaan.
                      Alle kosten en schade aan de zijde van DEQRGids en/of derden daardoor ontstaan, komen integraal voor rekening en risico
                      van de Klant.</li>
                  </ol>
                  <br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 10. | INTELLECTUELE EIGENDOM</h2>
                  <ol>
                    <li translate="no">Alle auteursrechten en overige rechten van intellectuele eigendom op het Platform en de in verband met de Overeenkomst
                      aangeboden software, behoren toe aan DEQRGids. Het is zonder voorafgaande Schriftelijke instemming van DEQRGids,
                      verboden het materiaal waarop de rechten van DEQRGids rusten, te verveelvoudigen, op enige wijze te reproduceren, te
                      verspreiden, te exploiteren of daarvan afgeleide werken te maken.</li>
                    <li translate="no">In geval van een aan de Klant toerekenbare inbreuk op het bepaalde in het vorige lid, behoudt DEQRGids zich alle rechten
                      voor die hem krachtens de wet toekomen, waaronder het recht op een naar redelijkheid door hem vast te stellen
                      schadevergoeding en onmiddellijke ongedaanmaking van de inbreuk.</li>
                  </ol>
                  <br />

                  <h2 className="mt-4 mb-3" translate="no">ARTIKEL 11. | SLOTBEPALINGEN</h2>
                  <ol>
                    <li translate="no">DEQRGids is gerechtigd zijn rechten en verplichtingen uit de Overeenkomst over te dragen aan een derde, bijvoorbeeld in
                      geval van een wijziging van zijn rechtsvorm.</li>
                    <li translate="no">Op elke Overeenkomst en alle daaruit tussen Partijen voortvloeiende rechtsverhoudingen is uitsluitend Nederlands recht
                      van toepassing.</li>
                    <li translate="no">Partijen zullen niet eerder een beroep doen op de rechter dan nadat zij zich optimaal hebben ingespannen om het geschil in
                      onderling overleg te beslechten. </li>
                    <li translate="no">Uitsluitend de bevoegde rechter binnen het arrondissement van de rechtbank Amsterdam wordt in eerste aanleg
                      aangewezen om van eventuele gerechtelijke geschillen tussen Partijen kennis te nemen, onverminderd het recht van
                      DEQRGids een andere volgens de wet bevoegde rechter aan te wijzen.</li>
                    <li translate="no">Indien deze algemene voorwaarden in meerdere talen beschikbaar zijn, is de Nederlandstalige versie daarvan steeds
                      bepalend voor de uitleg van de daarin opgenomen bedingen.</li>
                  </ol>
                  <br /><br />


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Header>
  );
}

export default Terms;
