import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const CookiesPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("cookiesConsent");
    if (!consent) {
      setShowPopup(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookiesConsent", "accepted", { expires: 365 });
    setShowPopup(false);
  };

  const handleDecline = () => {
    Cookies.set("cookiesConsent", "declined", { expires: 365 });
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div style={styles.popup}>
      <h3>This website uses cookies</h3>
      <p style={styles.message}>
        Click to agree and allow us to improve and personalize your experience on our website.<br /> You can change your mind at any time.{" "}
        <a href="/terms" target="_blank" style={styles.link}>
          About cookies
        </a>.
      </p>
      <div style={styles.buttons}>
        <button onClick={handleAccept} style={styles.accept}>
          Accept
        </button>
        <button onClick={handleDecline} style={styles.decline}>
          Decline
        </button>
      </div>
    </div>
  );
};

const styles = {
  popup: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    borderTop: "1px solid #ccc",
    padding: "1rem",
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    textAlign: "center",
  },
  message: {
    margin: 0,
    fontSize: '18px',
    paddingBottom: "0.5rem",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
  },
  accept: {
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    padding: "0.5rem 1rem",
    fontSize: '16px',
    borderRadius: "4px",
    cursor: "pointer",
  },
  decline: {
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    fontSize: '16px',
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default CookiesPopup;
