import React, { useEffect, useState } from 'react';
import NotFound from '../images/notfound.png';
import './Dashboard.css';
import Header from '../components/Header';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';
import Footer from '../components/Footer';
import SearchResult from '../components/SearchResult';
import ImageSlider from '../components/Section/ImageSlider';

const Home = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allQRCodes, setAllQRCodes] = useState([]);

  useEffect(() => {
    getLocalStorageInfo();
  }, []);

  const getLocalStorageInfo = async () => {
    try {
      let localData = reactLocalStorage.getObject("user");
      if (Object.keys(localData).length != 0) {
        setUserInfo(localData);
        getAllQRCodes(localData.id);
      } else {
        getAllQRCodes();
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getAllQRCodes = async (user_id) => {
    try {
      setIsLoading(true);
      let getAllQRCodesEndpoint = `/api/getAllQRCodes`;
      axios.get(getAllQRCodesEndpoint, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          if (response.data.status) {
            setAllQRCodes(response?.data?.result);
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log("error", error);
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Header>
      <div className="layout-center home-layout">
        {
          isLoading ?
            <div class="whole-loading">Loading&#8230;</div> :
            <>
              {/* <HomeCard /> */}
              <ImageSlider />
              {(allQRCodes && allQRCodes?.length > 0) ? <>
                <SearchResult data={allQRCodes} />
              </> : <>
                <div className="mt-3 mb-3 text-center">
                  <img src={NotFound} className="notFound" />
                  <h4 className="mb-3">No QRCode and Links Tree Found</h4>
                </div>
              </>}
              <div class="demo-request col-md-12">
                <span class="tabContent">
                  <p><span class="splunk2-eyebrow">PRICING PLANS</span></p>
                  <h2><span class="splunk2-h2">Choose a plan based on your business drivers</span></h2>
                  <p><span class="splunk-body">Work with your data, your way. With a variety of data models to choose from, you can find the approach that works best for you.</span></p>
                </span>
                <div class="splunk-button base-component parbase">
                  <a href="/pricing#estimate">
                    <button type="button" className={`mt-5 mb-5 btn estimate`}>Get Estimates & Demo</button>
                  </a>
                </div>
              </div>
            </>
        }
      </div>
      <Footer />
    </Header>
  );
};

export default Home;