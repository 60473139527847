import React from "react";

// Helper function to calculate total counts by browser
const calculateBrowserCounts = (data) => {
    const browserCounts = {};

    data.forEach(item => {
        const browser = item.dimensionValues[2].value; // Browser name
        const eventCount = parseInt(item.metricValues[0].value, 10); // Event count

        if (browserCounts[browser]) {
            browserCounts[browser] += eventCount;
        } else {
            browserCounts[browser] = eventCount;
        }
    });

    return Object.entries(browserCounts).map(([browser, eventCount]) => ({ browser, eventCount }));
};

const TechOverviewChartTable = ({ data }) => {
    if (!data || !Array.isArray(data)) {
        return <div>No data available to display the chart.</div>;
    }
    const browserData = calculateBrowserCounts(data);

    return (
        <div className="table-view-container mt-4">
            <table style={{ width: "94%", borderCollapse: "collapse", margin: "0 auto" }}>
                <thead>
                    <tr>
                        <th style={{ border: "1px solid #ccc", padding: "8px" }}>Browser</th>
                        <th style={{ border: "1px solid #ccc", padding: "8px" }}>Total Event Count</th>
                    </tr>
                </thead>
                <tbody>
                    {browserData.map(({ browser, eventCount }, index) => (
                        <tr key={index}>
                            <td style={{ border: "1px solid #ccc", padding: "8px" }}><b>{browser}</b></td>
                            <td style={{ border: "1px solid #ccc", padding: "8px" }}>{eventCount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TechOverviewChartTable;
