import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { formatDate } from "../../utils";

// Helper function to generate random colors
const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

// Updated `prepareChartData` function to group by browser and date
const prepareChartData = (data) => {
  const uniqueDates = [...new Set(data?.map(item => formatDate(item?.dimensionValues[1]?.value)))];
  const uniqueSortedDates = uniqueDates.sort((a, b) => {
    const [dayA, monthA, yearA] = a.split('-').map(Number);
    const [dayB, monthB, yearB] = b.split('-').map(Number);
  
    // Create comparable date values
    const dateA = new Date(yearA, monthA - 1, dayA); // Month is 0-indexed
    const dateB = new Date(yearB, monthB - 1, dayB);
  
    return dateA - dateB;
  });
  const uniqueBrowsers = [...new Set(data.map(item => item.dimensionValues[2].value))];

  const datasets = uniqueBrowsers.map(browser => {
    return {
      label: browser,
      data: uniqueSortedDates.map(date => {
        const item = data.find(d => d?.dimensionValues[2]?.value === browser && formatDate(d?.dimensionValues[1]?.value) === date);
        return item ? parseInt(item?.metricValues[0]?.value, 10) : 0;
      }),
      backgroundColor: getRandomColor(), // Assign a random color for each page title
    };
  });

  return {
    labels: uniqueSortedDates,
    datasets,
  };
};

const TechOverviewChart = ({ data }) => {
  if (!data || !Array.isArray(data)) {
    return <div>No data available to display the chart.</div>;
  }
  const chartData = prepareChartData(data);

  return (
    <div className="chart-container">
      <Bar
        data={chartData}
        style={{
          display: 'inline',
          height: '100% !important'
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Browser Usage Over Dates',
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Event Count',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default TechOverviewChart;
