import facebook from '../images/facebook.png';
import instagram from '../images/instagram.png';
import contact from '../images/contact.png';
import linkedin from '../images/linkedin.png';
import maps from '../images/maps.png';
import portfolio from '../images/portfolio.png';
import twitter from '../images/twitter.png';
import url from '../images/url.png';
import website from '../images/website.png';
import youtube from '../images/youtube.png';
import whatsapp from '../images/whatsapp.png';
import fanda from '../images/fanda.png';
import tiktok from '../images/tiktok.png';

export const Logos = [
    {
        id: 1,
        title: "Facebook",
        image: facebook
    },
    {
        id: 2,
        title: "Instagram",
        image: instagram
    },
    {
        id: 3,
        title: "Contact",
        image: contact
    },
    {
        id: 4,
        title: "Linkedin",
        image: linkedin
    },
    {
        id: 5,
        title: "Maps",
        image: maps
    },
    {
        id: 6,
        title: "Portfolio",
        image: portfolio
    },
    {
        id: 7,
        title: "Twitter",
        image: twitter
    },
    {
        id: 8,
        title: "URL",
        image: url
    },
    {
        id: 9,
        title: "Website",
        image: website
    },
    {
        id: 10,
        title: "Youtube",
        image: youtube
    },
    {
        id: 11,
        title: "WhatsApp",
        image: whatsapp
    },
    {
        id: 12,
        title: "Funda",
        image: fanda
    },
    {
        id: 13,
        title: "TikTok",
        image: tiktok
    },
]