import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./Register.css";
import { toast } from "react-toastify";
import axios from 'axios';
import Header from "../../components/Header";
import { createUniqueId, encodeUrl, validatePassword } from "../../utils";
import ReCAPTCHA from 'react-google-recaptcha';
import useRecaptcha from "../../hooks/useRecaptcha";
import WelcomePopup from "../Popup/WelcomePopup";


function Register() {
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const navigate = useNavigate();
  const [register, setRegister] = useState({});
  const [isCheckedTermsConditions, setIsCheckedTermsConditions] = useState(false);
  const [initRegister, setInitRegister] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const onInputchange = (event) => {
    setRegister((prevState) => ({
      ...prevState, // keep all other key-value pairs
      [event.target.name]: event.target.value, // update the value of specific key
    }));
  };

  const handleTermsConditionsChange = () => {
    setIsCheckedTermsConditions(!isCheckedTermsConditions);
  }

  const registerUser = () => {
    if (!("name" in register) || register.name === "") {
      toast.error("Name is Required Field!", {
        position: "top-right",
        autoClose: 2000,
      });
      return false;
    }
    if (!("email" in register) || register.email === "") {
      toast.error("Email is Required Field!", {
        position: "top-right",
        autoClose: 2000,
      });
      return false;
    }
    if (!("password" in register) || register.password === "") {
      toast.error("Password is Required Field!", {
        position: "top-right",
        autoClose: 2000,
      });
      return false;
    }
    if (!isCheckedTermsConditions) {
      toast.error("Accept Terms & Condition!", {
        position: "top-right",
        autoClose: 2000,
      });
      return false;
    }
    if (!capchaToken || capchaToken === "") {
      toast.error("Recaptcha is Missing!", {
        position: "top-right",
        autoClose: 2000,
      });
      return false;
    }

    const validationResult = validatePassword(register.password);
    if(validationResult.isValid) {
      submitRegisterRequest();
    } else {
      toast.error("Enter Valid Password!", {
        position: "top-right",
        autoClose: 3000,
      });
      return false;
    }
  };

  const submitRegisterRequest = () => {
    const registerEndpoint = "/api/register";
    const tokenEmail = encodeUrl(register.email);
    axios.post(registerEndpoint, {
      "unique_id": createUniqueId(register.name),
      "name": register.name,
      "email": register.email,
      "phone": register.phone,
      "password": register.password,
      "token": tokenEmail,
    },
      {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        if (response.data.status) {
          setRegister({});
          recaptchaRef.current?.reset();
          setInitRegister(true);
          setShowPopup(true);
        } else {
          if (response?.data?.result?.status === "registered") {
            setShowPopup(true);
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000
            });
          }
        }
      })
      .catch(function (error) {
        console.log("error", error);
        toast.error("Something went wrong try again later!", {
          position: "top-right",
          autoClose: 5000
        });
      });
  };

  const closePopup = () => {
    if(initRegister) {
      setShowPopup(false);
      window.location.href = "/login";
    } else {
      setShowPopup(false);
    }
  };

  return (
    <Header>
      <div className="container-fluid align-items-center justify-content-md-center h-p40  mt-5">
        <div className="row align-items-center justify-content-md-center h-p100  mt-5">
          <div className="col-12 mt-5">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <h2 className="text-primary">Sign In</h2>
                    <p className="mb-0">Sign in to continue to DeQRGids.</p>
                  </div>
                  <div role="form" className="register-form p-25">
                    <div className="form-group">
                      <label htmlFor="example-text-input" class="col-form-label">Company Name <span class="mandatory">*</span></label>
                      <div className="input-group mb-3">
                        <input
                          className="form-control ps-15 bg-transparent"
                          name="name"
                          placeholder="Enter Name"
                          autoComplete="Off"
                          defaultValue={register ? register.name : ""}
                          onChange={(text) => onInputchange(text)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="example-text-input" class="col-form-label">Email <span class="mandatory">*</span></label>
                      <div className="input-group mb-3">
                        <input
                          className="form-control ps-15 bg-transparent"
                          name="email"
                          placeholder="Enter Email Id"
                          autoComplete="Off"
                          defaultValue={register ? register.email : ""}
                          onChange={(text) => onInputchange(text)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="example-text-input" class="col-form-label">Phone Number</label>
                      <div className="input-group mb-3">
                        <input
                          className="form-control ps-15 bg-transparent"
                          name="phone"
                          placeholder="Enter Phone Number"
                          autoComplete="Off"
                          defaultValue={register ? register.phone : ""}
                          onChange={(text) => onInputchange(text)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="example-text-input" class="col-form-label">Password <span class="mandatory">*</span></label>
                      <div className="input-group mb-3">
                        <input
                          className="form-control ps-15 bg-transparent"
                          autoComplete="Off"
                          type="password"
                          name="password"
                          maxLength="12"
                          minLength="3"
                          placeholder="Enter Password"
                          defaultValue={register ? register.password : ""}
                          onChange={(text) => onInputchange(text)}
                        />
                      </div>
                      <div className="mt-2">
                        <div className="alert alert-warning" role="alert">
                          <p style={{fontSize: '12px', color: '#000'}}>{'Password Must Contain :'}<br/>
                          {'At least 1 uppercase character.'}<br/>
                          {'At least 1 lowercase character.'}<br/>
                          {'At least 1 digit.'}<br/>
                          {'At least 1 special character.'}<br/>
                          {'Minimum 8 characters.'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label class="col-form-label" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        <input
                          type="checkbox"
                          checked={isCheckedTermsConditions}
                          onChange={handleTermsConditionsChange}
                          style={{ marginRight: "10px" }}
                        />
                        I agree to the{" "}
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "blue", textDecoration: "underline", marginLeft: "5px", marginRight: '5px' }}
                        >
                          Terms and Conditions
                        </a>{" "}
                        of DeQRGids.nl <span class="mandatory">*</span>
                      </label>
                    </div>
                    <div className="form-group">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfjkYUqAAAAAAvVCBWqhSBvQExwWRbV9_CkNzal"
                        onChange={handleRecaptcha}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          onClick={() => registerUser()}
                          className="btn btn-danger mt-10">
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WelcomePopup showPopup={showPopup} closePopup={closePopup} />
    </Header>
  );
}

export default Register;
