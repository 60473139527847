import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import "./style.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";


function PrivacyPolicy() {

  return (
    <Header>
      <div className="layout-center mt-5">
        <div className="container-fluid align-items-center justify-content-md-center mt-5">
          <div className="row align-items-center justify-content-md-center mt-5">
            <div className="col-1"></div>
            <div className="col-10 mx-auto mt-5">
              <div className="row mt-5">
                <div className="col-md-10 mt-5 fontStyle fontStylePrivacy">
                  <h1 translate="no">PRIVACYVERKLARING DEQRGIDS</h1>

                  <p className="mt-3" translate="no">DEQRGids respecteert de privacy van eenieder van wie persoonsgegevens door DEQRGids worden verwerkt,
                    waaronder mede begrepen die van bezoekers en gebruikers van de website www.deqrgids.nl (hierna: de website).
                    DEQRGids acht de zorgvuldige omgang met persoonsgegevens van groot belang. DEQRGids zal uw persoonsgegevens
                    verwerken in overeenstemming met de Algemene Verordening Gegevensbescherming (AVG).<br /><br />
                    DEQRGids verwerkt persoonsgegevens op basis van de volgende vier wettelijke grondslagen:</p><br />

                  <h3 className="mt-4 mb-3" translate="no">1. Ter uitvoering van een overeenkomst tussen u en DEQRGids of uw verzoek tot het sluiten van een dergelijke
                    overeenkomst.</h3>
                  <p translate="no">Van deze grondslag is sprake wanneer u door registratie op de website een overeenkomst met DEQRGids hebt
                    gesloten om social media link trees en individuele links aan te maken onder uw account op de website. Bij de
                    registratie op de website dient u uw bedrijfsnaam, e-mailadres, telefoonnummer en een zelfgekozen wachtwoord op
                    te geven.</p>
                  <p translate="no">In het kader van deze wettelijke grondslag voor het verwerken van uw persoonsgegevens, verwerkt DEQRGids uw
                    persoonsgegevens uitsluitend indien en voor zover dat noodzakelijk is ter uitvoering van de met u gesloten
                    overeenkomst. Dat houdt in dat DEQRGids uw persoonsgegevens verwerkt (waaronder wordt verstaan, opslaan,
                    gebruiken en alle andere handelingen met betrekking tot uw persoonsgegevens) om u toegang te geven tot uw account
                    op de website en om u in staat te stellen om van de diensten van DEQRGids gebruik te maken. In dat kader worden
                    QR-codes voor toegang tot de door u gemaakte social media link trees en individuele links op de website geplaatst en
                    kunt u deze QR-codes zelf voor andere doeleinden gebruiken. Ook worden uw persoonsgegevens in het kader van de
                    uitvoering van de overeenkomst verwerkt om u uw betalingen af te wikkelen</p>
                  <p translate="no">In het kader van deze wettelijke grondslag, deelt DEQRGids uw persoonsgegevens ook met derden. Dit slechts indien
                    dat noodzakelijk is om u de functionaliteiten van de software voor het maken van social media link trees/links te
                    kunnen bieden. Denk hierbij aan een door DEQRGids ingeschakelde hostingpartij die de onder uw account op de
                    website opgeslagen persoonsgegevens opslaat.</p>
                  <p translate="no">Indien u eens een overeenkomst met DEQRGids hebt gesloten, mag DEQRGids u zonder voorafgaande uitdrukkelijke
                    instemming commerciële e-mailberichten toesturen waarvan de inhoud gelet op een eerdere overeenkomst tussen u
                    en DEQRGids, mogelijk interessant voor u is. U kunt zich voor de verdere ontvangst van dergelijke berichten
                    uitschrijven op de daartoe in de betreffende berichten aangegeven wijze.</p>
                  <p translate="no">Anoniem gebruik van QR-codes</p>
                  <p translate="no">DEQRGids genereert voor zijn klanten QR-codes. Deze kunnen door iedereen worden gescand waarmee men toegang
                    heeft tot de door de Klant geregistreerde social mediakanalen of andere content. DEQRGids verwerkt geen
                    persoonsgegevens van personen die de QR-codes scannen. Uitsluitend niet naar personen herleidbare gegevens
                    zoals de plaats en datum van scannen van de QR-codes worden door DEQRGids verwerkt. Deze gegevens worden
                    gebruikt om statistieken aan klanten van DEQRGids te tonen</p><br />
                  <p translate="no"><b>Een gerechtvaardigd belang van DEQRGids.</b></p>
                  <p translate="no">
                    Van deze wettelijke grondslag is sprake bij:<br />
                    - het door DEQRGids automatisch verstrekken van uw persoonsgegevens aan de door DEQRGids
                    ingeschakelde hostingpartij die de exploitatie en het functioneren van de website en voor het maken van
                    social media link trees/links mogelijk maakt. Dit is nodig om de middels de website en de software verwerkte
                    persoonsgegevens op te slaan en om de website, software en e-mailvoorzieningen van DEQRGids deugdelijk
                    te kunnen laten werken;<br />
                    - het gebruik van software voor het verzorgen van videocalls voor kennismakingen en demonstratie van de
                    software voor het maken van social media link trees/links;<br />
                    - het delen van factuurgegevens met een boekhoudkantoor en het gebruik van programmatuur voor het
                    verwerken en opslaan van de administratie en boekhouding van DEQRGids<br />
                    - het gebruik van nieuwsbriefsoftware om u de gevraagde nieuwsbrieven te versturen.<br />
                    - De exploitanten van de hierboven bedoelde software slaan relevante persoonsgegevens op om de
                    functionaliteiten van de software aan DEQRGids te kunnen aanbieden.<br />
                  </p>
                  <p translate="no">In het kader van ‘gerechtvaardigde belangen’ verwerkt DEQRGids uw persoonsgegevens uitsluitend indien en voor
                    zover dat noodzakelijk is om de betreffende gerechtvaardigde belangen te dienen.</p>
                  <br /><br />


                  <h3 className="mt-4 mb-3">2. Uw toestemming.</h3>
                  <p translate="no">
                    Van deze grondslag is sprake wanneer:<br />
                    - u via het contactformulier op de website, telefonisch, per e-mail of op welke andere wijze dan ook contact
                    met DEQRGids opneemt of zich via de website inschrijft voor de ontvangst van nieuwsbrieven van DEQRGids.
                    Uw toestemming ligt immers impliciet besloten in het feit dat u contact opneemt of zich inschrijft voor de
                    ontvangst van nieuwsbrieven;<br />
                    - DEQRGids uw persoonsgegevens voor nog andere doeleinden dan beschreven in deze privacyverklaring wil
                    gebruiken of aan derden wil verstrekken. In dit kader geldt dat uw toestemming steeds geldt voor een
                    specifieke verwerking en een specifiek doel. Indien DEQRGids meerdere doeleinden heeft om
                    persoonsgegevens te verwerken, dan zal DEQRGids u hierover informeren en voor elk doel afzonderlijk
                    toestemming vragen.<br />
                  </p>
                  <p translate="no">In dit kader verwerkt DEQRGids uw persoonsgegevens uitsluitend indien en voor zover dat nodig is om uw verzoek in
                    behandeling te nemen of uitvoering te geven aan hetgeen waarvoor u toestemming hebt gegeven.</p>


                  <h3 className="mt-4 mb-3" translate="no">3. Een wettelijke verplichting.</h3>
                  <p translate="no">Van deze grondslag is sprake wanneer DEQRGids moet voldoen aan een wettelijke verplichting, zoals wat betreft het
                    bewaren van facturen gedurende zeven jaren zoals door de wet voorgeschreven.</p>
                  <br />
                  <p translate="no">
                    <b>Europese Economische Ruimte </b><br />
                    DEQRGids slaat uw persoonsgegevens op binnen de Europese Economische Ruimte ("EER"). Het kan echter
                    voorkomen dat bepaalde persoonsgegevens die DEQRGids verzamelt, worden doorgegeven aan of opgeslagen buiten
                    de EER omdat een externe dienstverlener van DEQRGids daar gevestigd is. Wanneer hiervan sprake is, draagt
                    DEQRGids er zorg voor dat de verwerking van uw persoonsgegevens buiten de EER gebeurt op een veilige en
                    rechtmatige wijze. DEQRGids zal in dat verband dan ook de noodzakelijke stappen ondernemen om te garanderen dat
                    uw persoonsgegevens voldoende worden beschermd.
                  </p>
                  <p translate="no">
                    <b>Toegang tot persoonsgegevens  </b><br />
                    Binnen DEQRGids hebben uitsluitend daartoe geautoriseerde medewerkers van DEQRGids toegang tot uw
                    persoonsgegevens en uitsluitend voor zover dat nodig is ter uitvoering van de aan hen opgedragen taken. Dit geldt ook
                    ten aanzien van derde-partijen zoals hierboven bedoeld en met wie DEQRGids persoonsgegevens (automatisch)
                    deelt. DEQRGids zal uw persoonsgegevens onder geen beding verkopen. De personen die onder verantwoordelijkheid
                    van DEQRGids vallen, evenals door DEQRGids ingeschakelde derden, zijn verplicht om de vertrouwelijkheid van uw
                    persoonsgegevens te respecteren. Voor zover DEQRGids de verwerking van persoonsgegevens uitbesteedt aan een
                    derde partij, zal DEQRGids de noodzakelijke stappen ondernemen om te garanderen dat uw persoonsgegevens
                    voldoende worden beschermd. Zulke stappen omvatten, indien en voor zover dat volgens de wet is vereist, het
                    hanteren van een verwerkersovereenkomst met dergelijke ontvangers om te zorgen dat zij dezelfde of vergelijkbare
                    technische en organisatorische maatregelen treffen als DEQRGids, zodat uw persoonsgegevens voldoende worden
                    beschermd.
                  </p>
                  <p translate="no">
                    <b>Cookies die DEQRGids gebruikt </b><br />
                    DEQRGids gebruikt functionele en analytische cookies op de website. Een cookie is een klein tekstbestand dat bij uw
                    bezoek aan de website wordt opgeslagen in de browser van uw apparaat. Cookies met slechts een technische
                    functionaliteit (functionele cookies) zorgen ervoor dat de website naar behoren werkt. Deze cookies worden ook
                    gebruikt om de website te kunnen optimaliseren. <br />
                    Middels analytische cookies verzamelt DEQRGids gegevens voor onderzoek teneinde een beter inzicht te krijgen in
                    het gebruik van de website door de bezoekers daarvan. In dat kader maakt DEQRGids gebruik van Google Analytics
                    om te helpen analyseren op welke wijze de website wordt gebruikt door logboekgegevens en bezoekersgedrag op de
                    website in anonieme vorm te verzamelen. Het IP-adres van de bezoekers wordt echter gemaskeerd, er is een
                    verwerkersovereenkomst aangegaan met Google en er wordt geen data doorgestuurd voor aanvullende Googlediensten.<br />
                    U kunt het gebruik van cookies voorkomen of beperken door de juiste instellingen in uw internetbrowser te selecteren.
                    Indien u het gebruik van cookies beperkt, is het echter mogelijk dat de functionaliteiten van de website niet optimaal
                    kunnen worden benut.
                  </p>
                  <p translate="no">
                    <b>Geautomatiseerde besluitvorming</b><br />
                    DEQRGids gebruikt uw persoonsgegevens niet voor geautomatiseerde besluiten. Dit spreekt uiteraard voor zich,
                    echter is DEQRGids verplicht om dit te vermelden.
                  </p>
                  <p translate="no">
                    <b>Uw rechten</b><br />
                    U heeft het recht om DEQRGids te verzoeken om inzage, wissing en rectificatie van uw persoonsgegevens alsook om
                    beperking van de u betreffende verwerking. Ook heeft u het recht om tegen de verwerking bezwaar te maken en heeft
                    u recht op gegevensoverdraagbaarheid. Dat betekent dat u bij DEQRGids een verzoek kunt indienen om uw
                    persoonsgegevens die DEQRGids verwerkt, in een computerbestand naar u of een door u genoemde organisatie te
                    sturen <br />
                    Om er zeker van te zijn dat het verzoek door u is gedaan, vraagt DEQRGids u een kopie van uw identiteitsbewijs met
                    het verzoek mee te sturen. Maak in deze kopie de MRZ (machine readable zone, de strook met nummers op het
                    document), documentnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. DEQRGids
                    zal zo snel mogelijk, maar in elk geval binnen vier weken, reageren op uw verzoek. <br />
                    Indien de verwerking van uw persoonsgegevens gebaseerd is op de van u verkregen toestemming, heeft u te allen tijde
                    het recht deze toestemming in te trekken, zonder dat dit afbreuk doet aan de rechtmatigheid van de verwerking op
                    basis van de toestemming vóór de intrekking daarvan.<br />
                    U heeft het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens of in geval u in een ander land dan
                    Nederland woonachtig bent, de toezichthoudende autoriteit aldaar
                  </p>
                  <p translate="no">
                    <b>Bewaren en beveiligen van persoonsgegevens</b><br />
                    Persoonsgegevens worden door DEQRGids slechts bewaard voor zolang dat gelet op de doeleinden waarvoor zij zijn
                    verkregen, noodzakelijk is. DEQRGids legt passende technische en organisatorische maatregelen ten uitvoer om
                    persoonsgegevens te beveiligen tegen verlies en tegen enige vorm van onrechtmatige verwerking. Deze maatregelen
                    garanderen, rekening houdend met de stand van de techniek en de kosten van de tenuitvoerlegging, een passend
                    beveiligingsniveau gelet op de risico's die de verwerking en de aard van te beschermen persoonsgegevens met zich
                    meebrengen. De maatregelen zijn er mede op gericht onnodige verwerking van persoonsgegevens te voorkomen.
                  </p>
                  <p translate="no">
                    <b>Contact</b><br />
                    Voor vragen over deze privacyverklaring of vragen of klachten over de verwerking van uw persoonsgegevens door
                    DEQRGids, kunt u contact opnemen met DEQRGids. DEQRGids helpt u graag indien u vragen of klachten heeft over
                    de verwerking van uw persoonsgegevens door DEQRGids of indien u uw persoonsgegevens wilt wijzigen of
                    verwijderen. De contactgegevens van DEQRGids zijn op de website vermeld.
                  </p><br /><br />

                  <p className="mb-5" style={{float: "right", textAlign: "end"}}>
                    <b>DEQRGids</b><br />
                    (onderdeel van European IT Solutions)<br />
                    Tijnmuiden 59<br />
                    1046 AK Amsterdam</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Header>
  );
}

export default PrivacyPolicy;
