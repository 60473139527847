import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import "./style.css";
import { toast } from "react-toastify";
import axios from 'axios';
import Header from "../../components/Header";
import { decodeUrl } from "../../utils";


function ResetScreen() {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const decodedToken = decodeUrl(token);

  const navigate = useNavigate();
  const [resetPassword, setResetPassword] = useState({});

  const onInputchange = (event) => {
    setResetPassword((prevState) => ({
      ...prevState, // keep all other key-value pairs
      [event.target.name]: event.target.value, // update the value of specific key
    }));
  };

  const resetPasswordUser = () => {
    if (!("password" in resetPassword) || resetPassword.password === "") {
      toast.error("Password is Required Field!", {
        position: "top-right",
        autoClose: 2000
      });
      return false;
    }
    if (!("confirmPassword" in resetPassword) || resetPassword.confirmPassword === "") {
      toast.error("Confirm Password is Required Field!", {
        position: "top-right",
        autoClose: 2000
      });
      return false;
    }
    if (resetPassword.password != resetPassword.confirmPassword) {
      toast.error("Password not matched!", {
        position: "top-right",
        autoClose: 2000
      });
      return false;
    }

    // Reset Password
    submitResetPasswordRequest();
  };

  const submitResetPasswordRequest = () => {
    console.log("resetPassword", resetPassword);
    setIsLoading(true);
    const resetPasswordEndpoint = "/api/resetPassword";
    console.log("resetPasswordEndpoint", resetPasswordEndpoint);
    axios.post(resetPasswordEndpoint ,{
        "email" : decodedToken,
        "password": resetPassword.password,
        "confirm_password": resetPassword.confirmPassword
    }, 
    {
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        console.log("response resetPassword", response.data);
        if (response.data.status) {
          setIsLoading(false);
          toast.success("Password change successfully. Please Login!", {
            position: "top-right",
            autoClose: 2000
          });
          navigate("/login");
        } else {
          setIsLoading(false);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 2000
          });
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsLoading(false);
        toast.error("Something went wrong try again later!", {
          position: "top-right",
          autoClose: 2000
        });
      });
  };

  return (
    <Header>
     {isLoading && <div class="whole-loading">Loading&#8230;</div>}
      <div className="container-fluid align-items-center justify-content-md-center h-p40">
        <div className="row align-items-center justify-content-md-center h-p100">	
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <h2 className="text-primary">Reset Password</h2>
                  </div>
                  <div role="form" className="resetPassword-form p-40">
                      <div className="form-group">
                        <label htmlFor="example-text-input" class="col-form-label">Password <span class="mandatory">*</span></label>
                        <div className="input-group mb-3">
                          <input 
                            className="form-control ps-15 bg-transparent" 
                            autoComplete="Off"
                            type="password"
                            name="password"
                            maxLength="12"
                            minLength="3"
                            placeholder="enter Password"
                            required
                            defaultValue={resetPassword ? resetPassword.password : ""}
                            onChange={(text) => onInputchange(text)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="example-text-input" class="col-form-label">Confirm Password <span class="mandatory">*</span></label>
                        <div className="input-group mb-3">
                          <input 
                            className="form-control ps-15 bg-transparent" 
                            autoComplete="Off"
                            type="password"
                            name="confirmPassword"
                            maxLength="12"
                            minLength="3"
                            placeholder="enter confirm password"
                            required
                            defaultValue={resetPassword ? resetPassword.confirmPassword : ""}
                            onChange={(text) => onInputchange(text)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <button 
                            type="submit" 
                            onClick={() => resetPasswordUser()}
                            className="btn btn-danger mt-10">
                              Reset Password
                          </button>
                        </div>
                      </div>
                  </div>						
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default ResetScreen;
