import { useEffect } from 'react';
import Cookies from 'js-cookie';

const customTranslations = {
    nl: {},
    en: {
        pricing: "Pricing",
        clipboard: "Clipboard",
        details: "Details",
        "account status": "Account Status",
        register: "Register",
        logout: "Logout",
    },
    fr: {},
};

const GoogleTranslateWithCustomTranslations = () => {
    useEffect(() => {
        const loadGoogleTranslate = () => {
            if (!document.getElementById("google-translate-script")) {
                const script = document.createElement("script");
                script.id = "google-translate-script";
                script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
                script.async = true;
                document.body.appendChild(script);
            }
        };

        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "de",
                    includedLanguages: "nl,en,fr",
                    layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
                },
                "google_translate_element"
            );

            waitForLanguageDropdown(() => {
                setInitialLanguage();
                initializeCustomTranslations();
            });
        };

        const waitForLanguageDropdown = (callback) => {
            const interval = setInterval(() => {
                const languageDropdown = document.querySelector("select.goog-te-combo");
                if (languageDropdown) {
                    clearInterval(interval);
                    callback();
                }
            }, 1000);
        };

        const setInitialLanguage = () => {
            const savedLanguage = Cookies.get("selectedLanguage");
            const languageDropdown = document.querySelector("select.goog-te-combo");

            if (languageDropdown) {
                if (savedLanguage) {
                    languageDropdown.value = savedLanguage;
                    languageDropdown.dispatchEvent(new Event("change"));
                } else {
                    languageDropdown.value = "nl";
                    languageDropdown.dispatchEvent(new Event("change"));
                }

                languageDropdown.addEventListener("change", (e) => {
                    const selectedLanguage = e.target.value;
                    Cookies.set("selectedLanguage", selectedLanguage, { expires: 7 });
                });
            }
        };

        const initializeCustomTranslations = () => {
            const observer = new MutationObserver(() => {
                applyCustomTranslations();
            });

            observer.observe(document.body, { childList: true, subtree: true });
        };

        const applyCustomTranslations = () => {
            const lang = document.querySelector("select.goog-te-combo")?.value;
            if (lang && customTranslations[lang]) {
                const translations = customTranslations[lang];

                Object.keys(translations).forEach((originalText) => {
                    const textNodes = findExactTextNodes(document.body, originalText);
                    textNodes.forEach((node) => {
                        node.nodeValue = translations[originalText];
                    });
                });
            }
        };

        const findExactTextNodes = (node, textToFind) => {
            const textNodes = [];
            const treeWalker = document.createTreeWalker(
                node,
                NodeFilter.SHOW_TEXT,
                {
                    acceptNode: (currentNode) => {
                        if (currentNode.nodeValue.trim() === textToFind) {
                            return NodeFilter.FILTER_ACCEPT;
                        }
                        return NodeFilter.FILTER_REJECT;
                    },
                }
            );
            while (treeWalker.nextNode()) {
                textNodes.push(treeWalker.currentNode);
            }
            return textNodes;
        };

        loadGoogleTranslate();
    }, []);

    return (
        <li className="nav-item">
            <div id="google_translate_element"></div>
        </li>
    );
};

export default GoogleTranslateWithCustomTranslations;
