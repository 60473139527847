import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { formatDate } from "../../utils";

// Function to prepare data for the chart
const prepareChartData = (data) => {
  // Extract unique dates and page titles
  const uniqueDates = [...new Set(data?.map(item => formatDate(item?.dimensionValues[1]?.value)))];
  const uniqueSortedDates = uniqueDates.sort((a, b) => {
    const [dayA, monthA, yearA] = a.split('-').map(Number);
    const [dayB, monthB, yearB] = b.split('-').map(Number);
  
    // Create comparable date values
    const dateA = new Date(yearA, monthA - 1, dayA); // Month is 0-indexed
    const dateB = new Date(yearB, monthB - 1, dayB);
  
    return dateA - dateB;
  });
  const uniquePageTitles = [...new Set(data?.map(item => item?.dimensionValues[0]?.value))];

  // Organize data for each page title and date
  const datasets = uniquePageTitles.map(pageTitle => {
    return {
      label: pageTitle,
      data: uniqueSortedDates.map(date => {
        // Find the item that matches both pageTitle and date
        const item = data.find(d => d?.dimensionValues[0]?.value === pageTitle && formatDate(d?.dimensionValues[1]?.value) === date);
        return item ? parseInt(item?.metricValues[0]?.value, 10) : 0;
      }),
      backgroundColor: getRandomColor(), // Assign a random color for each page title
    };
  });

  return {
    labels: uniqueSortedDates,
    datasets,
  };
};

// Helper function to generate random colors
const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

const EventCountByEventNameChart = ({ data }) => {
  const chartData = prepareChartData(data);

  return (
    <div className="chart-container">
      <Line
        data={chartData}
        style={{
          display: 'inline',
          height: '100% !important'
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Events by Date',
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Event Count',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default EventCountByEventNameChart;
