import React, { useEffect, useState } from 'react';
import '../styles/pricing.css';
import PricingImg from '../images/pricing.png';
import Header from '../components/Header';
import { reactLocalStorage } from 'reactjs-localstorage';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import useRecaptcha from '../hooks/useRecaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Pricing = () => {
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    comment: "",
  });

  useEffect(() => {
    getLocalStorageInfo();
  }, []);

  const getLocalStorageInfo = async () => {
    try {
      let localData = reactLocalStorage.getObject("user");
      if (Object.keys(localData).length != 0) {
        setUserInfo(localData);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (capchaToken) {
        setIsLoading(true);
        const requestDemoEndpoint = "/api/requestDemo";
        console.log("requestDemoEndpoint", requestDemoEndpoint);
        axios.post(requestDemoEndpoint, formData,
          {
            headers: {
              'Content-Type': 'application/json'
            },
          })
          .then((response) => {
            if (response.data.status) {
              setIsLoading(false);
              toast.success("Thanks for showing intrest, our team will reach you sortly!", {
                position: "top-right",
                autoClose: 5000
              });
              recaptchaRef.current?.reset();
              navigate("/home");
            } else {
              setIsLoading(false);
              toast.error(response.data.message, {
                position: "top-right",
                autoClose: 2000
              });
            }
          })
          .catch(function (error) {
            console.log("error", error);
            setIsLoading(false);
            toast.error("Something went wrong try again later!", {
              position: "top-right",
              autoClose: 2000
            });
          });
    } else {
      toast.error("Please verify the captcha first!", {
        position: "top-right",
        autoClose: 2000
      });
    }
};

  return (
    <Header>
      {isLoading && <div class="whole-loading">Loading&#8230;</div>}
      <div className="layout-center pricing">
        <div class="content modifyPadding">
          <div class="container">
            <div class="row product">
              <div class="col-md-6 hasBottomPadding text-left">
                <h2 className="mb-4">Pricing</h2>
                <p class="splunk2-subhead-md splunk-color"> Explore DeQRGids options that align with your business needs. Our goal is to help you get more value from your data. Whether you need predictability or the flexibility to scale, you have options with DeQRGids.
                </p>
                <div>
                  <div class="splunk-button base-component parbase">
                    <a href="#estimate">
                      <button type="button" className={`mt-5 mb-5 btn estimate`}>Get Estimates & Demo</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 hasBottomPadding">
                <img
                  src={PricingImg}
                  alt="QR Info"
                  className="img-fluid rounded"
                />
              </div>
            </div>
            <div className="row product" id="estimate">
              <div class="col-md-12">
                <span class=" tabContent">
                  <p><span class="splunk2-eyebrow">PRICING PLANS</span></p>
                  <h2><span class="splunk2-h2">Choose a plan based on your business drivers</span></h2>
                  <p><span class="splunk-body">Work with your data, your way. With a variety of models to choose from, you can find the approach that works best for you.</span></p>
                </span>
              </div>
              <div className="text-left">
                <div className="col-md-8 order-2">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="login__textboxLabel">
                          First Name 
                          <span className="text-danger" style={{ fontSize: '18px', fontWeight: '900', marginLeft: '3px' }}>*</span></label>
                        <input 
                          type="text" 
                          className="form-control registerEmp__textbox"
                          name="firstName" 
                          placeholder="First Name" 
                          autoComplete="off" 
                          required 
                          value={formData?.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="login__textboxLabel">
                          Last Name
                          <span className="text-danger" style={{ fontSize: '18px', fontWeight: '900', marginLeft: '3px' }}>*</span></label>
                        <input 
                          type="text" 
                          className="form-control registerEmp__textbox"
                          name="lastName" 
                          placeholder="Last Name" 
                          autoComplete="off" 
                          required 
                          value={formData?.lastName}
                          onChange={handleInputChange} 
                        />
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className="form-group">
                          <label className="login__textboxLabel">Company Name</label>
                          <input 
                            type="text" 
                            className="form-control registerEmp__textbox"
                            name="companyName" 
                            placeholder="Company Name" 
                            autoComplete="none"
                          value={formData?.companyName}
                          onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className="form-group">
                          <label className="login__textboxLabel">
                            Email Address
                            <span className="text-danger" style={{ fontSize: '18px', fontWeight: '900', marginLeft: '3px' }}>*</span></label>
                          <input 
                            type="email" 
                            className="form-control registerEmp__textbox"
                            name="email" 
                            placeholder="Email Address" 
                            autoComplete="none" 
                            required 
                            value={formData?.email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className="form-group">
                          <label className="login__textboxLabel">Phone Number</label>
                          <input 
                            type="text" 
                            className="form-control registerEmp__textbox"
                            name="phoneNumber" 
                            placeholder="Phone Number" 
                            autoComplete="none" 
                            required 
                            value={formData?.phoneNumber}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <label className="login__textboxLabel">{'Comment'}</label>
                          <textarea
                            type="text"
                            className={"form-control"}
                            name="comment"
                            cols={4}
                            rows={6}
                            placeholder="Enter Comment"
                            autoComplete="none"
                            value={formData?.comment}
                            onChange={handleInputChange}> </textarea>
                        </div>
                      </div>
                      <div className="col-md-12 text-center mt-2">
                        <div className="d-flex justify-content-center">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LfjkYUqAAAAAAvVCBWqhSBvQExwWRbV9_CkNzal"
                            onChange={handleRecaptcha}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 text-right mt-2">
                        <div className="d-flex justify-content-center">
                          <div className="p-2">
                            <button 
                              type="submit" 
                              className={`mt-5 mb-5 btn estimate`}
                            >
                              Get Estimates & Demo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Header>
  );
};

export default Pricing;