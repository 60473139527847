import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./style.css";
import { toast } from "react-toastify";
import axios from 'axios';
import Header from "../../components/Header";
import { encodeUrl } from "../../utils";


function ForgotScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({});

  const onInputchange = (event) => {
    setForgotPassword((prevState) => ({
      ...prevState, // keep all other key-value pairs
      [event.target.name]: event.target.value, // update the value of specific key
    }));
  };

  const forgotPasswordUser = () => {
    if (!("email" in forgotPassword) || forgotPassword.email === "") {
      toast.error("Email is Required Field!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    // Login
    submitForgotPasswordRequest();
  };

  const submitForgotPasswordRequest = () => {
    console.log("webForgotScreen", forgotPassword);
    setIsLoading(true);
    const forgotPasswordEndpoint = "/api/forgotPassword";
    console.log("forgotPasswordEndpoint", forgotPasswordEndpoint);
    const tokenEmail = encodeUrl(forgotPassword.email);
    axios.post(forgotPasswordEndpoint ,{
        "email" : forgotPassword.email,
        "token": tokenEmail,
    }, 
    {
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        console.log("response forgotPassword", response.data);
        if (response.data.status) {
          setIsLoading(false);
          toast.success("Password Reset link send to Email. Please verify!", {
            position: "top-right",
            autoClose: 6000,
          });
          navigate("/home");
        } else {
          setIsLoading(false);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setIsLoading(false);
        toast.error("Something went wrong try again later!", {
          position: "top-right",
          autoClose: 2000,
        });
      });
  };

  return (
    <Header>
     {isLoading && <div class="whole-loading">Loading&#8230;</div>}
      <div className="container-fluid align-items-center justify-content-md-center h-p40">
        <div className="row align-items-center justify-content-md-center h-p100">	
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <h2 className="text-primary">Forgot Password</h2>
                    <p className="mb-0">Enter Registred email to get verification link.</p>							
                  </div>
                  <div role="form" className="forgotPassword-form p-40">
                      <div className="form-group">
                          <label htmlFor="example-text-input" class="col-form-label">Email <span class="mandatory">*</span></label>
                        <div className="input-group mb-3">
                          <input 
                            className="form-control ps-15 bg-transparent" 
                            name="email"
                            placeholder="enter email id"
                            autoComplete="Off"
                            defaultValue={forgotPassword ? forgotPassword.email : ""}
                            onChange={(text) => onInputchange(text)} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <button 
                            type="submit" 
                            onClick={() => forgotPasswordUser()}
                            className="btn btn-danger mt-10">
                              SUBMIT
                          </button>
                        </div>
                      </div>
                  </div>						
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default ForgotScreen;
