import React, { useState, useEffect } from "react";
import "./style.css";
import Mainpage1 from '../../images/slider/A1.png';
import Mainpage2 from '../../images/slider/A2.png';
import Mainpage3 from '../../images/slider/A3.png';
import Mainpage4 from '../../images/slider/A4.png';
import Mainpage5 from '../../images/slider/A5.png';

const ImageSlider = () => {
  const intervalTime = 8000; // 8 seconds
  const [currentImg, setCurrentImg] = useState(0);

  const images = [
    Mainpage1,
    Mainpage2,
    Mainpage3,
    Mainpage4,
    Mainpage5,
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImg((prev) => (prev + 1) % images.length);
    }, intervalTime);
    return () => clearInterval(timer);
  }, [images.length]);

  const changeSlide = (index) => {
    setCurrentImg(index);
  };

  return (
    <div className="slider-container">
      <div className="slider">
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Image ${index + 1}`}
            className={index === currentImg ? "active" : ""}
          />
        ))}
      </div>
      <div className="navigation-button">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentImg ? "active" : ""}`}
            onClick={() => changeSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
