import React, { useState } from 'react';
import './css/QRCodeTable.css';

import NotFound from '../images/notfound.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../config/env';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment';
import { checkIsNull, formatURL } from '../utils';
import { QRCodeSVG } from 'qrcode.react';

const QRCodeTable = ({ data, userInfo, isHome = false, isDashboard = false }) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [filteredItems, setFilteredItems] = useState(data);

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        setFilteredItems(
            data?.filter((item) =>
                item?.business_name?.toLowerCase().includes(value.toLowerCase()) ||
                item?.business_name?.toLowerCase().includes(value.toLowerCase()) ||
                item?.unique_id?.toLowerCase().includes(value.toLowerCase()) ||
                item?.qrcode_type?.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const clearSearch = () => {
        setSearchText('');
        setFilteredItems(data);
    };

    // Function to handle share action
    const navigateToSharePage = (qrcodeId) => {
        navigate(`/share/${qrcodeId}`);
        window.location.reload();
    };

    const navigateToAnalyticsView = (record) => {
        navigate(`/analytics/${record?.qrcode_type}/${record?.qrcode_id}`);
        window.location.reload();
    };

    const deleteQRCode = (record) => {
        var result = window.confirm("Are you sure Want to delete QRCode?");
        if (result) {
            axios
                .post(`/api/deleteQRCode`, {
                    user_id: userInfo.id,
                    id: record?.id
                },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                .then(function (response) {
                    if (response.data && response.data.status) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 2500,
                        });
                        setTimeout(() => {
                            window.location.reload(true);
                        }, 2000)
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 2500,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    toast.error('Something went wrong! Please Try Again later', {
                        position: "top-right",
                        autoClose: 2500,
                    });
                });
        }
    };

    return (
        <div className="search-container">
            {isHome && <div class="jobs__banner mt-5">
                <h1 class="homeScreen__tagline text-left">Find your QRCodes</h1>
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Search qrcodes..."
                        value={searchText}
                        onChange={handleSearch}
                        className="search-input"
                    />
                    {searchText && (
                        <FaTimes onClick={clearSearch} className="clear-icon" />
                    )}
                </div>
            </div>}
            <div className="qrcode-table qrcode-home-table">
                {isHome && <>
                    <h4 className="text-left" >Recently Created QRCode</h4>
                    <hr />
                </>}
                <table className="table-ui">
                    {(userInfo && userInfo?.id && isDashboard) && <thead>
                        <tr>
                            <th>Image</th>
                            <th>Business Name</th>
                            <th>URL</th>
                            <th>Creation Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>}
                    <tbody>
                        {(filteredItems && filteredItems?.length > 0) ? <>
                            {filteredItems.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div 
                                            className="qr-image" 
                                            onClick={() => {
                                                window.open(formatURL(item?.qrcode_url), '_blank', 'noopener,noreferrer');
                                            }}>
                                            <QRCodeSVG 
                                                value={item?.qrcode_url}
                                                size={128} 
                                                imageSettings={{
                                                    src: checkIsNull(item?.qrcode_logo) ? `${API.WEBSITE_URL}/${item?.qrcode_logo}` : null,
                                                    excavate: checkIsNull(item?.qrcode_logo) ? true : false,
                                                    height: checkIsNull(item?.qrcode_logo) ? 32 : 0,
                                                    width: checkIsNull(item?.qrcode_logo) ? 32 : 0,
                                                }}
                                                fgColor={item?.qrcode_color ? item?.qrcode_color : "#000"}
                                                className="grid-image"
                                            />
                                            <small className={item.qrcode_type === "linktree" ? "linktree-tag" : "qrcode-tag"}>
                                                <b>{item.qrcode_type === "linktree" ? "Links Tree" : "QR Code"}</b>
                                            </small>
                                        </div>
                                    </td>
                                    <td><strong>{item?.business_name}</strong></td>
                                    <td>
                                        <a href={item.qrcode_url} target="_blank" rel="noopener noreferrer">
                                            {item.qrcode_url}
                                        </a>
                                    </td>
                                    <td>{moment(item.date_created).format('DD-MM-YYYY')}</td>
                                    <td>
                                        <button onClick={() => navigateToSharePage(item?.qrcode_id)} className="share-button m-2">
                                            Share
                                        </button>
                                        {(userInfo && userInfo?.id && isDashboard) && <button onClick={() => navigateToAnalyticsView(item)} className="share-button m-2">
                                            View Analytics
                                        </button>}
                                        {(userInfo && userInfo?.id && isDashboard) && <button onClick={() => deleteQRCode(item)} className="share-button m-2">
                                            Delete
                                        </button>}
                                    </td>
                                </tr>
                            ))}
                        </> : <>
                            <div className="mt-3 text-center">
                                <img src={NotFound} className="notFound" />
                                <h4>No Result Record Found!</h4>
                            </div>
                        </>}

                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default QRCodeTable;
