import React, { useEffect, useState } from 'react';
import '../styles/LinkTree.css';
import { useParams } from 'react-router-dom';
import { formatURL, getLogoByTitle } from '../utils';
import Header from '../components/Header';
import axios from 'axios';
import ReactGA from 'react-ga4';
import API from '../config/env';
import Footer from '../components/Footer';
import LinkTreeCustomerProfile from '../components/LinkTreeCustomerProfile';

function ShareLinkTree() {
    const { uniqueId } = useParams();
    const [userInfo, setUserInfo] = useState(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        // Log page view with uniqueId
        ReactGA.send({
            hitType: 'pageview',
            page: `/share-tree/${uniqueId}`,
            title: `Share Tree Page - Link Tree ${uniqueId}`,
        });
    }, [uniqueId]);


    useEffect(() => {
        if(uniqueId) {
            getShareLinkTree(uniqueId)
        }
    }, [uniqueId]);

    const getShareLinkTree = async (uniqueId) => {
        try {
            const getShareLinkTreeEndpoint = `/api/getShareLinkTree/${uniqueId}`;
            axios.get(getShareLinkTreeEndpoint, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                if (response.data.status) {
                    setItems(response.data.result);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
        } catch (e) {
            console.log(e);
        }
    };

    const shareNowLinkTree = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share - get business up with DeQRGids',
                    text: 'Generate a custom QR code instantly to share any link and download your unique QR code to share online.',
                    url: `${API.WEBSITE_URL}/share-tree/${uniqueId}`,
                });
                console.log("Image shared successfully");
            } catch (error) {
                console.error("Error sharing:", error);
            }
        } else {
            console.error("Web Share API is not supported in your browser.");
        }
    }

    const Timeline = ({ items }) => {
        return (
            <div className="timeline-container">
                <div className="rocket-icon">
                    🚀 {/* Replace with a rocket image if needed */}
                </div>
                <div className="link-tree-customer-profile">
                    <LinkTreeCustomerProfile userInfo={null} uniqueId={uniqueId} isEdit={false} />
                </div>
                <div className="timeline">
                    {items.map((item, index) => (
                        <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                            <div className="timeline-content">
                                <a href={(item?.logo || item?.title) == "WhatsApp" ? `https://wa.me/${item.url.trim()}` :
                                    (item?.logo || item?.title) == "Contact" ? `tel:${item.url.trim()}` : formatURL(item.url.trim())}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="timeline-items">
                                        <div className="circle">{getLogoByTitle((item?.logo || item?.title)) != null ? <img src={getLogoByTitle((item?.logo || item?.title))} alt={item?.title} className='circle-logo' /> : index + 1}</div>
                                        <div className="item-content">
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <Header>
            <div className="linkTree layout-center">
                <h4>Share social media and other URL </h4>
                <Timeline items={items} />
                <button className="floating-button share-link-float" onClick={() => shareNowLinkTree()}>Share</button>
            </div>
            <Footer />
        </Header>
    );
}

export default ShareLinkTree;
