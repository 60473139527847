import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import QRCodeGenerator from './pages/QRCodeGenerator';
import QRCodeDisplay from './pages/QRCodeDisplay';
import Dashboard from './pages/Dashboard';
import QRCodeRedirect from './pages/QRCodeRedirect';
import LinkTree from './pages/LinkTree';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ShareLinkTree from './pages/ShareLinkTree';
import Analytics from './pages/Analytics';
import Home from './pages/Home';
import ForgotScreen from './pages/Password/ForgotScreen';
import ResetScreen from './pages/Password/ResetScreen';
import CookiesPopup from './components/CookiesPopup';
import Terms from './pages/Others/Terms';
import PrivacyPolicy from './pages/Others/PrivacyPolicy';
import AccountStatus from './pages/Others/AccountStatus';
import Pricing from './pages/Pricing';
import ActivateAccountScreen from './pages/Login/ActivateAccountScreen';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/create" element={<QRCodeGenerator />} />
          <Route path="/share/:qrcodeId" element={<QRCodeDisplay />} />
          <Route path="/redirect/:qrcodeId" element={<QRCodeRedirect />} />
          <Route path="/links-tree" element={<LinkTree />} />
          <Route path="/share-tree/:uniqueId" element={<ShareLinkTree />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/analytics/:qrcodeType/:qrcodeId" element={<Analytics />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotScreen />} />
          <Route path="/reset-password/:token" element={<ResetScreen />} />
          <Route path="/activate/:token" element={<ActivateAccountScreen />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/account-status" element={<AccountStatus />} />
        </Routes>
      </Router>
      <ToastContainer />
      <CookiesPopup />
    </div>
  );
}

export default App;
