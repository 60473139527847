import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { decodeUrl, formatURL } from '../utils';
import ReactGA from 'react-ga4';


const QRCodeRedirect = () => {
    const { qrcodeId } = useParams();
    const decodedUrl = decodeUrl(qrcodeId);

    useEffect(() => {
        if (decodedUrl) {
            // Log the page view first
            ReactGA.send({
                hitType: 'pageview',
                page: `/redirect/${qrcodeId}`,
                title: `Share QRCode Page - QRCode ${qrcodeId}`,
            });

            // Add a slight delay to ensure the analytics request is sent
            setTimeout(() => {
                window.location.href = formatURL(decodedUrl);
            }, 100); // Adjust the delay as necessary
        }
    }, [qrcodeId, decodeUrl]);



    return (
        <div className="redirect-layout app-center">
            <div className="loader"></div>
        </div>
    );
};

export default QRCodeRedirect;
