import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCamera } from 'react-icons/fa';
import './css/LinkTreeCustomerProfile.css'; // Import CSS file for styling
import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import API from '../config/env';

const LinkTreeCustomerProfile = ({ userInfo, uniqueId, isEdit }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (uniqueId) {
      getProfileRecord();
    }
  }, []);

  const getProfileRecord = async () => {
    try {
      const getProfileEndpoint = `/api/getProfile/${uniqueId}`;
      axios.get(getProfileEndpoint, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          if (response.data.status) {
            setPreview(`${API.WEBSITE_URL}/${response.data.result[0]?.image}`);
            reactLocalStorage.setObject("user", response.data.result[0]);
          }
        })
        .catch(function (error) {
          console.log("error", error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async () => {
    if (!selectedImage) return;

    // Create a new file with the updated filename
    const updatedFile = new File(
      [selectedImage],
      'profile.png', // Replace with the desired filename
      { type: selectedImage.type }
    );

    const formData = new FormData();
    formData.append('image', updatedFile);
    formData.append('id', userInfo?.id);

    try {
      await axios.post('/api/upload/profileImage', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        toast.success("Image uploaded successfully!", {
          position: "top-right",
          autoClose: 2000
        });
        setSelectedImage(null);
        setPreview(null);
        getProfileRecord();
      }).catch((error) => {
        console.log("error", error);
        toast.error("Something went wrong try again later!", {
          position: "top-right",
          autoClose: 2000
        });
      });
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (
    <>
      {
        isEdit ? <div className="image-upload-container">
          <div className="image-upload-wrapper" onClick={() => document.getElementById('file-input').click()}>
            <input
              id="file-input"
              type="file"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <div className="image-preview">
              {preview ? (
                <img src={preview} alt="Selected" className="image-preview-round" />
              ) : (
                <div className="image-placeholder">
                  <FaCamera size={40} />
                </div>
              )}
              <div className="camera-icon-overlay">
                <FaCamera size={20} />
              </div>
            </div>
          </div>
          <button className="custom-button" onClick={handleSubmit} disabled={!selectedImage}>
            Submit
          </button>
        </div> : <div className="image-upload-container">
          <div className="image-upload-wrapper">
            <div className="image-preview">
              {preview ? (
                <img src={preview} alt="Selected" className="image-preview-round" />
              ) : (
                <div className="image-placeholder">
                  <FaCamera size={40} />
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default LinkTreeCustomerProfile;
