import React, { useState, useEffect } from "react";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, subDays, format, isWithinInterval } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { FaCalendarAlt } from "react-icons/fa";

const DateFilter = ({ data, onFilter }) => {
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const filterData = (filter) => {
        const today = new Date();
        let filteredData = data;

        switch (filter) {
            case "All":
                filteredData = data;
                break;
            case "Today":
                filteredData = data.filter(item => item.dimensionValues[1].value === format(today, "yyyyMMdd"));
                break;
            case "Yesterday":
                const yesterday = subDays(today, 1);
                filteredData = data.filter(item => item.dimensionValues[1].value === format(yesterday, "yyyyMMdd"));
                break;
            case "This Week":
                const start = format(startOfWeek(today), "yyyyMMdd");
                const end = format(endOfWeek(today), "yyyyMMdd");
                filteredData = data.filter(item => item.dimensionValues[1].value >= start && item.dimensionValues[1].value <= end);
                break;
            case "Last Week":
                const lastWeekStart = format(startOfWeek(subDays(today, 7)), "yyyyMMdd");
                const lastWeekEnd = format(endOfWeek(subDays(today, 7)), "yyyyMMdd");
                filteredData = data.filter(item => item.dimensionValues[1].value >= lastWeekStart && item.dimensionValues[1].value <= lastWeekEnd);
                break;
            case "Last Month":
                const lastMonthStart = format(startOfMonth(subDays(today, 30)), "yyyyMMdd");
                const lastMonthEnd = format(endOfMonth(subDays(today, 30)), "yyyyMMdd");
                filteredData = data.filter(item => item.dimensionValues[1].value >= lastMonthStart && item.dimensionValues[1].value <= lastMonthEnd);
                break;
            case "Custom Date":
                setIsModalOpen(true)
                break;
            default:
                break;
        }
        onFilter(filteredData);
    };

    useEffect(() => {
        filterData(selectedFilter);
    }, [selectedFilter]);

    const handleDateFilterApply = () => {
        if (startDate && endDate) {
            let filteredData = data;
            const formattedStartDate = format(startDate, "yyyyMMdd");
            const formattedEndDate = format(endDate, "yyyyMMdd");
            filteredData = data.filter(item => {
                const itemDate = item.dimensionValues[1].value;
                return itemDate >= formattedStartDate && itemDate <= formattedEndDate;
            });
            onFilter(filteredData);
        } else {
            toast.error("Please enter Start and End Date!", {
                position: "top-right",
                autoClose: 2000
            });
        }
    }

    return (
        <div>
            <div className="main-filter">
                <label>Date Filter:</label>
                <select value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value)}>
                    <option value="All">All</option>
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="This Week">This Week</option>
                    <option value="Last Week">Last Week</option>
                    <option value="Last Month">Last Month</option>
                    <option value="Custom Date">Custom Date</option>
                </select>
            </div>

            {isModalOpen && (<div className="date-filter">
                <div className="date-layout">
                    <div className="start-date-layout" style={{ margin: "0px 12px" }}>
                        <label>Start Date:</label>
                        <div style={{ position: "relative", display: "inline-block" }}>
                            {/* DatePicker component */}
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                customInput={
                                    <input
                                        style={{
                                            paddingLeft: "2.5rem", // Add padding for the icon
                                            width: "200px",
                                            height: "32px",
                                            borderRadius: "5px",
                                            border: "1px solid #ccc",
                                            outline: "none",
                                        }}
                                    />
                                }
                            />
                            {/* Icon */}
                            <FaCalendarAlt
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "10px",
                                    transform: "translateY(-50%)",
                                    color: "#888",
                                }}
                            />
                        </div>
                    </div>
                    <div className="end-date-layout">
                        <label>End Date:</label>
                        <div style={{ position: "relative", display: "inline-block" }}>
                            {/* DatePicker component */}
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                maxDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                customInput={
                                    <input
                                        style={{
                                            paddingLeft: "2.5rem", // Add padding for the icon
                                            width: "200px",
                                            height: "32px",
                                            borderRadius: "5px",
                                            border: "1px solid #ccc",
                                            outline: "none",
                                        }}
                                    />
                                }
                            />
                            {/* Icon */}
                            <FaCalendarAlt
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "10px",
                                    transform: "translateY(-50%)",
                                    color: "#888",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="btn-date-layout">
                    <button onClick={() => {
                        setStartDate(null);
                        setEndDate(null);
                        setIsModalOpen(false)
                        setSelectedFilter('All')
                    }}>Cancel</button>
                    <button onClick={handleDateFilterApply}>Apply</button>
                </div>
            </div>)}
        </div>
    );
};

export default DateFilter;
