import React, { useEffect, useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useParams } from 'react-router-dom';
import { LinkedinIcon, FacebookIcon, TwitterIcon } from 'react-share';
import { checkIsNull, decodeUrl, formatURL, getWebsiteName } from '../utils';
import Header from '../components/Header';
import API from '../config/env';
import * as QRCodeJS from 'qrcode';
import Footer from '../components/Footer';
import axios from 'axios';

const QRCodePopup = ({ decodedUrl, handleShare, qrcodeId, QRCodeInfo }) => {
    const qrRef = useRef();
    const [qrCodeDownloadUrl, setQrCodeDownloadUrl] = useState("");

    const handleCopy = () => {
        const textToCopy = `${API.WEBSITE_URL}/redirect/${qrcodeId}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => alert("Copied to clipboard!"))
                .catch((err) => console.error("Failed to copy text: ", err));
        } else {
            // Fallback for non-HTTPS environments
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            textArea.style.position = "fixed"; // Avoid scrolling to bottom of the page in some cases
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand("copy");
                alert("Copied to clipboard!");
            } catch (err) {
                console.error("Fallback: Failed to copy text: ", err);
            }

            document.body.removeChild(textArea);
        }

    };

    React.useEffect(() => {
        if (qrcodeId) {
            const redirectURL = `${API.WEBSITE_URL}/redirect/${qrcodeId}`;
            const fgColor = QRCodeInfo?.qrcode_color || "#000"; // Default to black if not set
    
            QRCodeJS.toDataURL(redirectURL, { errorCorrectionLevel: "H" })
                .then((qrCodeDataUrl) => {
                    const qrImage = new Image();
                    qrImage.crossOrigin = "anonymous"; // Allow cross-origin image loading
                    qrImage.src = qrCodeDataUrl;
    
                    const logoImage = new Image();
                    if (checkIsNull(QRCodeInfo?.qrcode_logo)) {
                        logoImage.crossOrigin = "anonymous"; // Allow cross-origin image loading
                        logoImage.src = `${API.WEBSITE_URL}/${QRCodeInfo?.qrcode_logo}`;
                    }
    
                    qrImage.onload = () => {
                        const canvas = document.createElement("canvas");
                        const context = canvas.getContext("2d");
    
                        const padding = 20; // Padding on the sides
                        const textHeight = 30; // Space for the text
    
                        const qrWidth = qrImage.width;
                        const qrHeight = qrImage.height;
                        canvas.width = qrWidth + padding * 2;
                        canvas.height = qrHeight + textHeight + padding;
    
                        context.fillStyle = "#FFFFFF";
                        context.fillRect(0, 0, canvas.width, canvas.height);
    
                        const qrCanvas = document.createElement("canvas");
                        const qrCtx = qrCanvas.getContext("2d");
    
                        qrCanvas.width = qrWidth;
                        qrCanvas.height = qrHeight;
    
                        qrCtx.drawImage(qrImage, 0, 0);
    
                        const imageData = qrCtx.getImageData(0, 0, qrWidth, qrHeight);
                        const data = imageData.data;
    
                        for (let i = 0; i < data.length; i += 4) {
                            if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0 && data[i + 3] > 0) {
                                const [r, g, b] = [
                                    parseInt(fgColor.slice(1, 3), 16),
                                    parseInt(fgColor.slice(3, 5), 16),
                                    parseInt(fgColor.slice(5, 7), 16),
                                ];
                                data[i] = r;
                                data[i + 1] = g;
                                data[i + 2] = b;
                            }
                        }
                        qrCtx.putImageData(imageData, 0, 0);
    
                        context.drawImage(qrCanvas, padding, padding, qrWidth, qrHeight);
    
                        if (checkIsNull(QRCodeInfo?.qrcode_logo)) {
                            logoImage.onload = () => {
                                const logoSize = Math.min(qrWidth / 4, qrHeight / 4);
                                const logoX = padding + qrWidth / 2 - logoSize / 2;
                                const logoY = padding + qrHeight / 2 - logoSize / 2;
    
                                context.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
    
                                context.font = "12px Arial";
                                context.textAlign = "center";
                                context.fillStyle = "#000";
                                context.fillText(
                                    "Gemaakt door DeQRGids.nl",
                                    canvas.width / 2,
                                    qrHeight + padding + textHeight / 2
                                );
    
                                const finalImageUrl = canvas.toDataURL("image/png");
                                setQrCodeDownloadUrl(finalImageUrl);
                            };
                        } else {
                            context.font = "12px Arial";
                            context.textAlign = "center";
                            context.fillStyle = "#000";
                            context.fillText(
                                "Gemaakt door DeQRGids.nl",
                                canvas.width / 2,
                                qrHeight + padding + textHeight / 2
                            );
    
                            const finalImageUrl = canvas.toDataURL("image/png");
                            setQrCodeDownloadUrl(finalImageUrl);
                        }
                    };
                })
                .catch((err) => {
                    console.error("Error generating QR code", err);
                });
        }
    }, [qrcodeId, QRCodeInfo?.qrcode_logo, QRCodeInfo?.qrcode_color]);
    


    return (
        <div className="popup">
            <h1>Share QR Code Generator</h1>
            <div ref={qrRef}
                className="qrCodeContainer"
                onClick={() => {
                    const redirectURL = `${API.WEBSITE_URL}/redirect/${qrcodeId}`
                    window.open(formatURL(redirectURL), '_blank', 'noopener,noreferrer');
                }}>
                <QRCodeSVG
                    value={`${API.WEBSITE_URL}/redirect/${qrcodeId}`}
                    size={256}
                    imageSettings={{
                        src: checkIsNull(QRCodeInfo?.qrcode_logo) ? `${API.WEBSITE_URL}/${QRCodeInfo?.qrcode_logo}` : null,
                        excavate: checkIsNull(QRCodeInfo?.qrcode_logo) ? true : false,
                        height: checkIsNull(QRCodeInfo?.qrcode_logo) ? 75 : 0,
                        width: checkIsNull(QRCodeInfo?.qrcode_logo) ? 75 : 0,
                    }}
                    fgColor={QRCodeInfo?.qrcode_color ? QRCodeInfo?.qrcode_color : "#000"}
                />
            </div>
            <br />

            {/* Convert the QRCode to a downloadable image */}
            <div>
                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${API.WEBSITE_URL}/redirect/${qrcodeId}&title=Share - get business up with DeQRGids&summary=Generate a custom QR code instantly to share any link and download your unique QR code to share online.?utm_source=linkedin&utm_medium=social&utm_campaign=linkedin_social_vac_share`} target="_blank" style={{ marginRight: '8px' }}>
                    <LinkedinIcon size={32} round={true} />
                </a>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${API.WEBSITE_URL}/redirect/${qrcodeId}?utm_source=facebook&utm_medium=social&utm_campaign=facebook_social_vac_share`} target="_blank" style={{ marginRight: '8px' }}>
                    <FacebookIcon size={32} round={true} />
                </a>
                <a href={`https://twitter.com/intent/tweet?url=${API.WEBSITE_URL}/redirect/${qrcodeId}?utm_source=twitter&utm_medium=social&utm_campaign=twitter_social_vac_share&utm_term=business&text=Share - get business up with DeQRGids`} target="_blank" style={{ marginRight: '8px' }}>
                    <TwitterIcon size={32} round={true} />
                </a>
            </div>
            <br /><br />
            <button className="btn green" onClick={handleCopy}>Copy to Clipboard</button>
            <button className="btn green margin-left" onClick={handleShare}>Share QR Code</button>
            {qrCodeDownloadUrl && (<a href={qrCodeDownloadUrl} download={`${getWebsiteName(decodedUrl)}.png`}>
                <button className="btn green margin-left">Download</button>
            </a>)}
        </div>
    );
}

const QRCodeDisplay = () => {
    const { qrcodeId } = useParams();
    const decodedUrl = decodeUrl(qrcodeId);
    const [isLoading, setIsLoading] = useState(true);
    const [QRCodeInfo, setQRCodeInfo] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        if (qrcodeId) {
            getQRCodeDetails(qrcodeId)
        }
    }, [qrcodeId]);

    const getQRCodeDetails = async (qrcodeId) => {
        try {
            setIsLoading(true)
            const getQRCodeDetailsEndpoint = `/api/getQRCodeDetails/${qrcodeId}`;
            axios.get(getQRCodeDetailsEndpoint, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    if (response.data.status) {
                        setQRCodeInfo(response.data.result[0]);
                        setIsLoading(false)
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    setIsLoading(false)
                });
        } catch (e) {
            console.log(e);
            setIsLoading(false)
        }
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share - get business up with DeQRGids',
                    text: 'Generate a custom QR code instantly to share any link and download your unique QR code to share online.',
                    url: `${API.WEBSITE_URL}/redirect/${qrcodeId}`,
                });
                console.log("Image shared successfully");
            } catch (error) {
                console.error("Error sharing:", error);
            }
        } else {
            console.error("Web Share API is not supported in your browser.");
        }
    };


    return (
        <Header>
            <div className="create-center">
                {isLoading && <div class="whole-loading">Loading&#8230;</div>}
                <div className="create-app-center pt-5">
                    {isLoading && <div class="whole-loading">Loading&#8230;</div>}
                    {(QRCodeInfo && QRCodeInfo != null) ? <QRCodePopup decodedUrl={decodedUrl} handleShare={handleShare} qrcodeId={qrcodeId} QRCodeInfo={QRCodeInfo} /> : <>
                        <div className="mt-3 text-center">
                            <h1>QRCode Loading...</h1>
                        </div>
                    </>}
                </div>

                <div class="demo-request col-md-12">
                    <span class="tabContent">
                        <p><span class="splunk2-eyebrow">PRICING PLANS</span></p>
                        <h2><span class="splunk2-h2">Choose a plan based on your business drivers</span></h2>
                        <p><span class="splunk-body">Work with your data, your way. With a variety of data models to choose from, you can find the approach that works best for you.</span></p>
                    </span>
                    <div class="splunk-button base-component parbase">
                        <a href="/pricing#estimate">
                            <button type="button" className={`mt-5 mb-5 btn estimate`}>Get Estimates & Demo</button>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </Header>
    );
};

export default QRCodeDisplay;
