import React, { useState } from 'react';
import './Popup.css'; // Optional: For styling

const WelcomePopup = ({showPopup, closePopup}) => {

    return (
        <>
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h2>Dear User,</h2>
                        <p>Welcome on De QR Gids.</p>
                        <p>
                            An email is sent to you with the account activation link. Please
                            check your email and click on the Activate button in the email.
                        </p>
                        <p>(*Please check your Spam and Junk folders also)</p>
                        <p>Thanks,</p>
                        <p>Kind Regards,</p>
                        <p>Team De QR Gids.</p>
                        <button className="close-button" onClick={closePopup}>
                            Close message
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default WelcomePopup;
