import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./Login.css";
import API from "../../config/env";
import { reactLocalStorage } from "reactjs-localstorage";
import { toast } from "react-toastify";
import axios from 'axios';
import Header from "../../components/Header";
import useRecaptcha from "../../hooks/useRecaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import WelcomePopup from "../Popup/WelcomePopup";


function Login() {
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const navigate = useNavigate();
  const [login, setLogin] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const onInputchange = (event) => {
    setLogin((prevState) => ({
      ...prevState, // keep all other key-value pairs
      [event.target.name]: event.target.value, // update the value of specific key
    }));
  };

  const loginUser = () => {
    if (!capchaToken || capchaToken === "") {
      toast.error("Recaptcha is Missing!", {
        position: "top-right",
        autoClose: 2000,
      });
      return false;
    }
    if (!("email" in login) || login.email === "") {
      toast.error("Email is Required Field!", {
        position: "top-right",
        autoClose: 2000
      });
      return false;
    }
    if (!("password" in login) || login.password === "") {
      toast.error("Password is Required Field!", {
        position: "top-right",
        autoClose: 2000
      });
      return false;
    }

    // Login
    submitLoginRequest();
  };

  const submitLoginRequest = () => {
    const loginEndpoint = "/api/login";
    axios.post(loginEndpoint, {
      "email": login.email,
      "password": login.password
    },
      {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        if (response.data.status) {
          reactLocalStorage.setObject("user", response.data.result);
          toast.success("Login Successfully!", {
            position: "top-right",
            autoClose: 5000
          });
          recaptchaRef.current?.reset();
          window.location.href = "/dashboard";
        } else {
          if (response?.data?.result?.status === "registered") {
            setShowPopup(true);
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000
            });
          }
        }
      })
      .catch(function (error) {
        console.log("error", error);
        toast.error("Something went wrong try again later!", {
          position: "top-right",
          autoClose: 5000
        });
      });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <Header>
      <div className="container-fluid align-items-center justify-content-md-center h-p40">
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <h2 className="text-primary">Sign In</h2>
                    <p className="mb-0">Sign in to continue to DeQRGids.</p>
                  </div>
                  <div role="form" className="login-form p-40">
                    <div className="form-group">
                      <label htmlFor="example-text-input" class="col-form-label">Email</label>
                      <div className="input-group mb-3">
                        <input
                          className="form-control ps-15 bg-transparent"
                          name="email"
                          placeholder="Email Id"
                          autoComplete="Off"
                          defaultValue={login ? login.email : ""}
                          onChange={(text) => onInputchange(text)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="example-text-input" class="col-form-label">Password</label>
                      <div className="input-group mb-3">
                        <input
                          className="form-control ps-15 bg-transparent"
                          autoComplete="Off"
                          type="password"
                          name="password"
                          maxLength="12"
                          minLength="3"
                          placeholder="Password"
                          defaultValue={login ? login.password : ""}
                          onChange={(text) => onInputchange(text)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfjkYUqAAAAAAvVCBWqhSBvQExwWRbV9_CkNzal"
                        onChange={handleRecaptcha}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          onClick={() => loginUser()}
                          className="btn btn-danger mt-10">
                          SIGN IN
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-5 mb-5">
                      <div className="col-12 text-center">
                        <a href="/forgot-password">Forgot Password</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WelcomePopup showPopup={showPopup} closePopup={closePopup} />
    </Header>
  );
}

export default Login;
