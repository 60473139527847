import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import '../styles/LinkTree.css';
import { encodeUrl, formatURL, getLogoByTitle } from '../utils';
import { Logos } from '../utils/mock';
import Header from '../components/Header';
import { reactLocalStorage } from "reactjs-localstorage";
import API from '../config/env';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import LinkTreeCustomerProfile from '../components/LinkTreeCustomerProfile';
import Footer from '../components/Footer';


// Custom Option component to display image and title in the dropdown
const customOption = (props) => (
    <div {...props.innerProps} className="customOption" style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <img src={props.data.image} alt={props.data.title} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
        <span>{props.data.title}</span>
    </div>
);

function LinkTree() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [items, setItems] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({ title: '', url: '', description: '' });
    // Map the data to fit react-select's options structure
    const options = Logos.map(item => ({
        value: item.id,
        label: item.title,
        image: item.image,
        title: item.title,
    }));
    const [qrCodeUrl, setQRCodeUrl] = useState('');
    const [qrCodeImage, setQRCodeImage] = useState('');
    const svgRef = useRef(null);

    useEffect(() => {
        getLocalStorageInfo();
    }, []);

    const getLocalStorageInfo = async () => {
        try {
            let localData = reactLocalStorage.getObject("user");
            if (Object.keys(localData).length != 0) {
                setUserInfo(localData)
                console.log("localData",localData)

                const qrCodeUrl = `${API.WEBSITE_URL}/share-tree/${localData?.unique_id}`;
                setQRCodeUrl(qrCodeUrl);

                getLinkTree(localData.id)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getLinkTree = async (user_id) => {
        try {
            const getLinkTreeEndpoint = `/api/getLinkTree/${user_id}`;
            axios.get(getLinkTreeEndpoint, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    if (response.data.status) {
                        setItems(response.data.result);
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleAddShareClick = () => {
        const textToCopy = `${API.WEBSITE_URL}/share-tree/${userInfo?.unique_id}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => alert("Copied to clipboard!"))
                .catch((err) => console.error("Failed to copy text: ", err));
        } else {
            // Fallback for non-HTTPS environments
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            textArea.style.position = "fixed"; // Avoid scrolling to bottom of the page in some cases
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand("copy");
                alert("Copied to clipboard!");
            } catch (err) {
                console.error("Fallback: Failed to copy text: ", err);
            }

            document.body.removeChild(textArea);
        }

    };

    const handleGenerateAndShareClick = () => {
        setIsLoading(true);
        fetch('/api/verifyLinkTreeAvailableOrNot', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                logo_url: userInfo?.image,
                unique_id: userInfo?.unique_id,
                qrcode_type: 'linktree'
            }),
        }).then((response) => response.json())
            .then((response) => {
                console.log('response', response)
                if (response.status) {
                    navigate(`/share/${response?.result[0]?.qrcode_id}`);
                    window.location.reload();
                } else {
                    const svg = svgRef.current.querySelector('svg');
                    const svgData = new XMLSerializer().serializeToString(svg);
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const img = new Image();

                    img.onload = async function () {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);
                        const pngFile = canvas.toDataURL('image/png');
                        setQRCodeImage(pngFile);

                    };

                    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
                }
                setIsLoading(false);
            }).catch((error) => {
                console.log("error", error);
                setIsLoading(false);
                toast.error("Something went wrong try again later!", {
                    position: "top-right",
                    autoClose: 2000
                });
            });
    };

    useEffect(() => {
        if (qrCodeImage) {
            setIsLoading(true);
            const byteString = atob(qrCodeImage.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uint8Array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                uint8Array[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([uint8Array], { type: 'image/png' });
            const file = new File([blob], 'qrcode.png', { type: 'image/png' });

            const formData = new FormData();
            formData.append('user_id', userInfo?.id);
            formData.append('unique_id', userInfo?.unique_id);
            formData.append('qrcode_url', qrCodeUrl);
            formData.append('image', file);

            const qrcodeId = encodeUrl(qrCodeUrl);
            formData.append('qrcode_id', qrcodeId);

            formData.append('logo_url', userInfo?.image);
            formData.append('qrcode_color', '#000');

            const qrcodeType = qrCodeUrl?.includes("share-tree") ? "linktree" : "qrcode"
            formData.append('qrcode_type', qrcodeType);

            fetch('/api/addQRCodes', {
                method: 'POST',
                body: formData,
            }).then(() => {
                setIsLoading(false);
                toast.success("QRCode Created Successfully!!", {
                    position: "top-right",
                    autoClose: 2000
                });
                navigate(`/share/${qrcodeId}`);
                window.location.reload();
            }).catch((error) => {
                setIsLoading(false);
                console.log("error", error);
                toast.error("Something went wrong try again later!", {
                    position: "top-right",
                    autoClose: 2000
                });
            });
        }
    }, [qrCodeImage])

    const handleAddClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedItem(null);
        setFormData({ title: '', url: '', description: '' });
    };

    const handleSelectChange = (selected) => {
        console.log('selected', selected)
        setSelectedItem(selected);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        console.log("userInfo", userInfo)
        e.preventDefault();
        if(selectedItem && selectedItem?.title != null) {
            setIsLoading(true);
            let parmas = {
                ...formData,
                logo: selectedItem?.title,
                user_id: userInfo?.id,
                unique_id: userInfo?.unique_id
            }
            const addLinkTreeEndpoint = "/api/addLinkTree";
            console.log("addLinkTreeEndpoint", addLinkTreeEndpoint);
            axios.post(addLinkTreeEndpoint, parmas, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    console.log("response addLinkTree", response.data);
                    if (response.data.status) {
                        setIsLoading(false);
                        toast.success("Link Added to Tree Successfully!", {
                            position: "top-right",
                            autoClose: 2000
                        });
                        // setItems((prevItems) => [...prevItems, formData]);
                        getLinkTree(userInfo.id);
                        handleClosePopup();
                    } else {
                        setIsLoading(false);
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 2000
                        });
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    setIsLoading(false);
                    toast.error("Something went wrong try again later!", {
                        position: "top-right",
                        autoClose: 2000
                    });
                });
        } else {
            toast.error("Please Select Logo Type!", {
                position: "top-right",
                autoClose: 2000
            }); 
        }
    };

    const deleteLink = (record) => {
        var result = window.confirm("Are you sure Want to delete Link?");
        if (result) {
            axios
                .post(`/api/deleteLink`, {
                    user_id: userInfo.id,
                    id: record?.id
                },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                .then(function (response) {
                    if (response.data && response.data.status) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 2500,
                        });
                        getLinkTree(userInfo.id);
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 2500,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    toast.error('Something went wrong! Please Try Again later', {
                        position: "top-right",
                        autoClose: 2500,
                    });
                });
        }
    };

    const Timeline = ({ items }) => {
        return (
            <div className="timeline-container">
                {/* <div className="rocket-icon">
                    🚀
                </div> */}
                <div className="link-tree-customer-profile">
                    <LinkTreeCustomerProfile userInfo={userInfo} uniqueId={userInfo?.unique_id} isEdit={true} />
                </div>
                <div className="timeline">
                    {items.map((item, index) => (
                        <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                            <div className="timeline-content">
                                <a href={(item?.logo || item?.title) == "WhatsApp" ? `https://wa.me/${item.url.trim()}` :
                                    (item?.logo || item?.title) == "Contact" ? `tel:${item.url.trim()}` : formatURL(item.url.trim())}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="timeline-items">
                                        <div className="circle">{getLogoByTitle((item?.logo || item?.title)) != null ? <img src={getLogoByTitle((item?.logo || item?.title))} alt={(item?.logo || item?.title)} className='circle-logo' /> : index + 1}</div>
                                        <div className="item-content">
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </a>
                                <button onClick={() => deleteLink(item)} className={`timeline-button ${index % 2 === 0 ? 'timeline-start' : 'timeline-end'}`}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <Header>
            {isLoading && <div class="whole-loading">Loading&#8230;</div>}
            <div className="linkTree layout-center">
                <h4>Add own social media and other URL </h4>
                <Timeline items={items} />
                <div ref={svgRef} className="d-none">
                    {qrCodeUrl && <QRCodeSVG 
                        value={qrCodeUrl} 
                        size={256} 
                        imageSettings={{
                            src: userInfo?.image ? userInfo?.image : null,
                            excavate: userInfo?.image ? true : false,
                            height: userInfo?.image ? 60 : 0,
                            width: userInfo?.image ? 60 : 0,
                        }}
                        fgColor={"#000"}
                    />}
                </div>
                <button className="share-floating-button" onClick={handleGenerateAndShareClick}>Generate & Share</button>
                <button className="floating-button" onClick={handleAddClick}>Add Link</button>
                {showPopup && (
                    <div className="popup">
                        <div className="popup-content">
                            <h2 className="popup-title">Add New URL</h2>
                            <hr />
                            <form onSubmit={handleSubmit}>
                                <label>
                                    Logo <span class="mandatory">*</span>:
                                    <Select
                                        options={options}
                                        onChange={handleSelectChange}
                                        value={selectedItem}
                                        placeholder="Select an option"
                                        className="link-logo-select"
                                        required
                                        components={{
                                            Option: customOption,
                                            SingleValue: ({ data }) => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={data.image} alt={data.title} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                                                    {data.title}
                                                </div>
                                            ),
                                        }}
                                    />
                                </label>
                                <label>
                                    Title <span class="mandatory">*</span>:
                                    <input
                                        type="text"
                                        placeholder={"Enter Title"}
                                        name="title"
                                        value={formData.title}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </label>
                                <label>
                                    URL <span class="mandatory">*</span>:
                                    <input
                                        type={(selectedItem?.title == "WhatsApp" || selectedItem?.title == "Contact") ? "number" : "text"}
                                        placeholder={(selectedItem?.title == "WhatsApp" || selectedItem?.title == "Contact") ? "Enter Mobile number" : "Enter URL"}
                                        name="url"
                                        value={formData.url}
                                        onChange={handleFormChange}
                                        required
                                    />
                                    <br />

                                </label>
                                <label>
                                    Description:
                                    <textarea
                                        name="description"
                                        maxLength={255}
                                        value={formData.description}
                                        onChange={handleFormChange}
                                    />
                                </label>
                                <div className="popup-button">
                                    <button type="button" disabled={isLoading} onClick={handleClosePopup}>Cancel</button>
                                    <button type="submit" disabled={isLoading} className="green">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </Header>
    );
}

export default LinkTree;
