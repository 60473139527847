import React from 'react';
import './css/RealtimeReport.css';

const RealtimeReport = ({ realtimeReport }) => {
  const activeUserCount = realtimeReport?.reduce((sum, row) => sum + parseInt(row?.metricValues[0]?.value || 0, 10), 0);
  
  return (
    <div className="realtime-stats-container realtime-view">
      <div className="header">
        <h4>Active Users in Last 30 Minutes <small>(Realtime Activity)</small></h4>
        <span className="active-users-count">{activeUserCount}</span>
      </div>

      <div className="chart-section">
        <h6>Active Users Per Minute</h6>
        <div className="bar-chart">
          {Array.from({ length: 10 }).map((_, index) => (
            <div key={index} className="bar" style={{ height: `${Math.random() * 100}%` }}></div>
          ))}
        </div>
      </div>

      <table className="country-table">
        <thead>
          <tr>
            <th>Top Countries</th>
            <th>City</th>
            {/* <th>Page</th> */}
            <th>Active Users</th>
            <th>Page Views</th>
          </tr>
        </thead>
        <tbody>
          {realtimeReport?.map((row, index) => (
            <tr key={index}>
              <td>{row?.dimensionValues[0]?.value || 'N/A'}</td>
              <td>{row?.dimensionValues[1]?.value || 'N/A'}</td>
              {/* <td>{row?.dimensionValues[2]?.value || 'N/A'}</td> */}
              <td>{row?.metricValues[0]?.value || '0'}</td>
              <td>{row?.metricValues[1]?.value || '0'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RealtimeReport;
