import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import ReportWithPageViewCount from '../components/ReportWithPageViewCount';
import BatchReport from '../components/BatchReport';
import RealtimeReport from '../components/RealtimeReport';
import ReportWithEventCountByEventName from '../components/ReportWithEventCountByEventName';
import Header from '../components/Header';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import ReportWithTechOverview from '../components/ReportWithTechOverview';
import ReportWithLeadOverview from '../components/ReportWithLeadOverview';

const Analytics = () => {
  const { qrcodeType, qrcodeId } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [realtimeReport, setRealtimeReport] = useState(null);
  const [batchReport, setBatchReport] = useState(null);
  const [reportWithPageViewCount, setReportWithPageViewCount] = useState(null);
  const [reportWithEventCountByEventName, setReportWithEventCountByEventName] = useState(null);
  const [reportWithTechOverview, setReportWithTechOverview] = useState(null);
  const [reportWithLeadOverview, setReportWithLeadOverview] = useState(null);

  useEffect(() => {
    getLocalStorageInfo();
  }, []);

  const getLocalStorageInfo = async () => {
    try {
      let localData = reactLocalStorage.getObject("user");
      if (Object.keys(localData).length != 0) {
        setUserInfo(localData);
        fetchRealtimeReport(localData.unique_id);
        fetchBatchReport(localData.unique_id);
        fetchReportWithPageViewCount(localData.unique_id);
        fetchReportWithEventCountByEventName(localData.unique_id);
        fetchReportWithTechOverview(localData.unique_id);
        fetchReportWithLeadOverview(localData.unique_id);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const fetchRealtimeReport = async (uniqueId) => {
    let params = { 
      eventPath: qrcodeType == "linktree" ? `/share-tree/${uniqueId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${uniqueId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getRealtimeReport";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        const filteredData = response?.data?.filter(item => item !== null);
        setRealtimeReport(filteredData);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchBatchReport = async (uniqueId) => {
    let params = { 
      eventPath: qrcodeType == "linktree" ? `/share-tree/${uniqueId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${uniqueId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getBatchReport";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        const filteredData = response?.data?.filter(item => item !== null);
        setBatchReport(filteredData);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithPageViewCount = async (uniqueId) => {
    let params = { 
      eventPath: qrcodeType == "linktree" ? `/share-tree/${uniqueId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${uniqueId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithPageViewCount";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithPageViewCount(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithEventCountByEventName = (uniqueId) => {
    let params = {
      eventPath: qrcodeType == "linktree" ? `/share-tree/${uniqueId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${uniqueId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithEventCountByEventName";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithEventCountByEventName(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithTechOverview = (uniqueId) => {
    let params = {
      eventPath: qrcodeType == "linktree" ? `/share-tree/${uniqueId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${uniqueId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithTechOverview";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithTechOverview(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithLeadOverview = (uniqueId) => {
    let params = {
      eventPath: qrcodeType == "linktree" ? `/share-tree/${uniqueId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${uniqueId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithLeadOverview";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithLeadOverview(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  return (
    <Header>
      <div className="layout-center">
        <h1>VISITOR MONITORING REPORT</h1>
        {
          (userInfo && userInfo != null) && <h4>{qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${userInfo?.unique_id}` : `Share QRCode Page - QRCode ${qrcodeId}`}</h4>
        }
        <hr />
        <div className="chat-row">
          {(realtimeReport && realtimeReport.length > 0) && <RealtimeReport realtimeReport={realtimeReport} />}

          {(batchReport && batchReport.length > 0) && <BatchReport batchReport={batchReport} />}
        </div>

        <div className="midium-vertical-space"></div>
        <ReportWithPageViewCount reportWithPageViewCount={reportWithPageViewCount} />

        <div className="midium-vertical-space"></div>
        <ReportWithEventCountByEventName reportWithEventCountByEventName={reportWithEventCountByEventName} />

        <div className="midium-vertical-space"></div>
        <ReportWithTechOverview reportWithTechOverview={reportWithTechOverview} />

        <div className="midium-vertical-space"></div>
        <ReportWithLeadOverview reportWithLeadOverview={reportWithLeadOverview} />

      </div>
      <Footer />
    </Header>
  );
};

export default Analytics;